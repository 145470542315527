export const referenceProjects = [
  {
    title: 'Aave',
    ticker: 'AAVE',
    shortDescription:
      'AAVE is a decentralized finance (DeFi) protocol that enables users to borrow and lend cryptocurrencies without intermediaries. It allows users to earn interest on their deposits and borrow assets at variable or stable interest rates. AAVE is built on the Ethereum blockchain and uses smart contracts to automate lending and borrowing operations',
    sector: 'DeFi',
    problem:
      'Centralized lending platforms have limitations on access, flexibility, and transparency for borrowing and lending in the cryptocurrency market.',
    solution:
      'Aave provides a decentralized platform that allows users to borrow and lend crypto with preset rules using smart contracts, making the process automated, transparent, and accessible. Overcollateralized loans and liquidation of collateral protect lenders from losses.',
    mission:
      'Aave is committed to revolutionizing the lending sector through a decentralized autonomous organization on the Ethereum blockchain. It empowers users to borrow and lend cryptocurrencies efficiently, utilizing smart contracts for secure, intermediary-free transactions, thereby fostering a unique, transparent, and flexible loan market within the decentralized finance (DeFi) ecosystem​.',
    vision:
      'Aave envisions a robust and expansive DeFi ecosystem, offering a diverse range of services and products. It aims to provide a decentralized platform for flexible and transparent financial transactions, promoting true community engagement in governance and decision-making, and continuously innovating to meet user needs in a secure, decentralized finance landscape',
    valueCreation:
      'The value created by Aave is its decentralized liquidity protocol enables borrowing and lending of crypto assets without intermediaries. Its innovative features, including flash loans and interest-earning functionality, create a more inclusive financial system. A stable interest rate model provides stability for borrowers and lenders. Aave has transformed DeFi with a secure, efficient, and transparent platform.',
    revenueFrom: '0.09% fees charged from borrowers to borrow funds, paid to lenders in the same asset',
    revenueDenominatedIn: 'The same asset as the borrowed funds and AAVE token',
    revenueTo:
      'Lenders as fees for providing liquidity\nTreasury for operational expenses, investments, and incentives SM ',
    valueCaptureToken:
      "Fees from lending, borrowing, and flash loans (0.09%) are directed to the protocol's treasury, governed by Aave token holders.",
    valueCaptureProtocol:
      "Aave's value is reflected by governance decisions on fee accruals, with token holders managing the funds from lending/borrowing fees and flash loan fees.",
    demandDrivers: [
      '- Staking rate is still low relative to the circulating supply and it only pays out the native token. Also, the protocol does not redistribute much of the fees it creates to its users like',
      'Governance',
    ],
    tokenUtility:
      'Utilities: Governance and staking.\nHolder can vote on allocation of treasury funds, risk parameters, the amount of tokens issued and stake their tokens into the Safety Module (SM) to provide funds that can be used to secure the protocol',
    coingeckoApiKey: 'aave',
    price: 105.6,
    marketCap: 1545509817,
    fdv: 1686429368,
    volume: 102421426,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Aave%20Tokenomics.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1c330a0Ws3jPfv_xz1NceaUncaaweFAxw%26export%3Ddownload',
    parent:
      'Compound - A decentralized lending protocol that enables users to lend and borrow cryptocurrencies.\nMakerDAO - A decentralized lending platform that allows users to generate DAI stablecoins by locking up collateral assets.',
    strongPoints:
      'Already emitted 88% of total supply and about 25% of total supply is staked\nCurrent staking APY for Aave is 6.44% (adjusted APY 5.2%) \nIt is the biggest in lending and borrowing market',
    weakPoints:
      'Aave token does not directly capture the value the protocol create\nUtility does not create enough demand while future utility creates speculative opportunity',
    image: 'https://assets.coingecko.com/coins/images/12645/standard/AAVE.png?1696512452',
  },
  {
    title: 'Balancer',
    ticker: 'BAL',
    shortDescription:
      "Balancer is an automated market maker (AMM) protocol that allows for flexible pool creation and trading of assets on Ethereum. It also features ve-tokens that represent users' shares in the pool's fees, allowing for more efficient governance and rewards distribution.",
    sector: 'DeFi',
    problem:
      'Traders face difficulty in finding the optimal prices for trades due to limited liquidity access and inefficient trading systems.',
    solution:
      'Balancer protocol offers a Smart Order Routing feature that finds the best prices for traders. By pooling liquidity from investor portfolios, Balancer ensures access to sufficient liquidity while maintaining efficient trading. The flexible pool system and ve-Token with fee share make trading cost-effective and user-friendly.',
    mission:
      "Balancer's mission is to revolutionize decentralized exchange by offering an open, trustless, and permissionless trading platform. It focuses on providing flexibility and control to users, facilitating token swaps, liquidity pooling, and yield earning in a decentralized manner, leveraging its native BAL token for governance and incentivizing platform engagemen",
    vision:
      'Balancer envisions a future where decentralized finance is accessible and efficient, empowering users with novel trading and liquidity options. It aims to differentiate with unique features like multi-asset pools and user-defined fees, fostering a community-governed platform that encourages participation, innovation, and equitable distribution of rewards among its stakeholders',
    valueCreation:
      "The value created by the Balancer protocol lies in its ability to provide a flexible AMM solution that allows for the creation of custom pools with multiple tokens. This leads to a more efficient trading experience with increased liquidity and minimal slippage. Additionally, the introduction of ve-BAL with fee share incentivizes users to hold Balancer's governance token, creating a strong community and aligning the interests of all stakeholders. ",
    revenueFrom:
      'Fees collected from trades and withdrawals in the pools\nSince users have to lock ETH-BAL liquidity positions to receive veBAL, this reduces the circulating supply of BAL and thus also increases the value of the treasury.',
    revenueDenominatedIn: 'Whatever assets pool is denominated in',
    revenueTo: 'Lockers of veBAL',
    valueCaptureToken:
      "veBAL receives 75% of fees. This mechanism helps to increase the value of BAL due to reduced circulating supply, in essence, it's an incentive to lock up more BAL and thus map value onto the token. Token holders also have a say in the usage of the fees collected.",
    valueCaptureProtocol:
      'The Balancer protocol captures value through fees collected from trades and withdrawals in the pools with 25% going to the treasury. This mechanism helps to increase the value of the treasury.',
    demandDrivers: [
      'Governance & Voting Power:\nHolders of $BAL tokens have the power to vote on important protocol decisions such as treasury management, fee structure, and liquidity mining rewards. Additionally, veBAL holders receive 75% of the protocol fees, incentivizing long-term holding and active participation in governance.',
      'Liquidity Provision:\nLiquidity providers can earn $BAL tokens by providing liquidity to Balancer pools. The more liquidity provided, the more fees earned and $BAL tokens received as a reward. This creates a strong incentive for users to hold $BAL tokens and provide liquidity to Balancer pools.',
      'DeFi Ecosystem Integration:\nBalancer protocol has gained significant traction in the DeFi ',
    ],
    tokenUtility:
      "$BAL\nLocking BAL tokens can earn veBAL, which provides governance rights within the Balancer protocol\nveBAL holders can also earn a share of the protocol's trading fees\nHolding veBAL can also provide access to certain Balancer features and benefits, such as liquidity mining opportunities\n\n$LP tokens\nBalancer allows for the creation of customizable liquidity pools, which can be composed of multiple tokens\nHolders of these pool tokens can earn trading fees from the pool, as well as potentially benefit from arbitrage opportunities\nThese tokens can also be used as collateral in lending and borrowing protocols, or traded on other platforms for potential profit.",
    coingeckoApiKey: 'balancer',
    price: 3.97,
    marketCap: 213592131,
    fdv: 240150829,
    volume: 8892635,
    diagramUrl:
      'https://viewer.diagrams.net/?highlight=0000ff&edit=_blank&nav=1&title=tokenomics_Balancer.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D12J2yq3FoA2iw2usdZz80EAAgnuUc0oa2%26export%3Ddownload',
    parent:
      'Uniswap - A decentralized exchange that uses an automated market maker (AMM) system to enable trading between Ethereum-based tokens.\nCurve Finance - A decentralized exchange focused on stablecoins that uses a unique algorithm to provide low-slippage trades.\nBancor - A decentralized liquidity network that allows for easy conversion between Ethereum-based tokens by using smart contracts to provide automatic market-making services.',
    strongPoints:
      'Smaller DEX by TVL with interesting technical implementation of pools\nMost of cheap, early tokens have been unlocked by now\nExciting yield bearing token that shares part of the fees with VE token holders',
    weakPoints: 'However, more than 50% are still to be unlocked',
    image: 'https://assets.coingecko.com/coins/images/11683/standard/Balancer.png?1696511572',
  },
  {
    title: 'Brave',
    ticker: 'BAT',
    shortDescription:
      'Brave protocol is a privacy-focused web browser that blocks ads and trackers by default. It rewards users with its native cryptocurrency, BAT, for viewing opt-in ads and supports publishers through a revenue-sharing model. It aims to provide a more equitable and transparent advertising ecosystem while enhancing user privacy and experience.',
    sector: 'Advertising/Consumer Products',
    problem:
      "Traditional online advertising is often intrusive, and irrelevant, and often undermines users' privacy by tracking their online behavior.",
    solution:
      "Brave Protocol is a browser that blocks unwanted ads, reduces load times, and protects users' privacy while rewarding them for engaging with relevant ads through its Basic Attention Token (BAT) system. This incentivizes users to opt-in to ads and reduces the need for intrusive tracking.",
    mission:
      "Brave's mission is to enhance online privacy and security, offering a suite of tools including a privacy-focused browser and search engine. These tools are designed to protect users from intrusive ads and trackers, ensuring a safer, more private browsing experience without compromising on functionality or ease of use",
    vision:
      "Brave envisions a web environment grounded in privacy, neutrality, and freedom. Striving to restore the web's original ethos, Brave focuses on tangible privacy solutions, closing data loopholes and defending against big tech's schemes. It aims to make privacy straightforward and accessible, embedding robust security directly into its products",
    valueCreation:
      'The Brave Browser provides easy access to web3 and natively blocks third-party ads and trackers to preserve user privacy. The Brave Browser natively supports advertising (Brave Ads), video calling (Brave Talk), VPN and Firewall, news feed (Brave News), private browsing with Tor, and a web3 wallet (Brave Wallet). Brave also developed its own search engine (Brave Search).',
    revenueFrom:
      'selling ad inventory, collecting a 5% fee from donations to publishers, and collecting fees from token swaps in the Brave Wallet.',
    revenueDenominatedIn: 'Ethereum, and Basic Attention Token (BAT).',
    revenueTo:
      'Brave Software, publishers, and liquidity providers. Brave Software retains the majority of revenue from ad sales and token swaps, while publishers receive 95% of donations.',
    valueCaptureToken:
      "Brave's Basic Attention Token (BAT) captures value through supply and demand dynamics, where its value increases with increased usage and adoption of the Brave browser and BAT ecosystem.",
    valueCaptureProtocol:
      'Brave protocol captures value through its advertising business, with 70% of revenue used to buy back BAT from the market, and fees collected from publisher donations and token swaps in Brave Wallet. These funds go to the protocol treasury, which can be used for further development and ecosystem growth.',
    demandDrivers: [
      'Buyback: \nInvestors and traders buy and hold $BRAVE as most of the advertising revenue (70%) is used to buy back $BAT from the market, creating demand and a potential price increase.',
      'User Growth: \nAs more users adopt Brave browser, the demand for $BAT increases as it is used as a utility token to reward users for engaging with the platform, driving demand from users and advertisers alike.',
    ],
    tokenUtility:
      '$BAT\nEarn BAT for watching Brave Ads: Users can choose to receive ads while using the Brave browser and earn BAT as a reward for their attention.\nDonate BAT to publishers: Users can support their favorite websites and content creators by donating BAT directly to them.\nWithdraw BAT through a CEX wallet: Users can exchange their BAT for other cryptocurrencies or fiat money by withdrawing it from their Brave Rewards wallet to a supported CEX wallet.',
    coingeckoApiKey: 'basic-attention-token',
    price: 0.235645,
    marketCap: 351237848,
    fdv: 353497000,
    volume: 67989015,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=BAT%20Tokenomics%20v3.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1OSo0MOKF86A-ZLnQ2nhWRnOnSnNGXKTL%26export%3Ddownload',
    parent:
      'Presearch - a decentralized search engine that rewards users with PRE tokens for their searches and allows them to customize their search results based on their preferences.\n\nAdEx - a decentralized advertising platform that aims to improve transparency and reduce fraud in the advertising industry by utilizing blockchain technology and smart contracts. It rewards users for viewing ads and enables advertisers to target relevant audiences.',
    strongPoints: 'Great software and popular browser',
    weakPoints:
      'low utility makes BAT extremely vulnerable to market sentiment\nBAT liquidity is highly concentrated in a few hands (the top 1% wallets own 92% of the EOA supply)\nalthough Brave excels in value creation, value capture is weak',
    image: 'https://assets.coingecko.com/coins/images/677/standard/basic-attention-token.png?1696501867',
  },
  {
    title: 'Chainlink',
    ticker: '$LINK',
    shortDescription:
      'Chainlink is a decentralized oracle network that bridges the gap between blockchain applications and real-world data. It enables smart contracts to securely interact with external data sources, ensuring the reliability and integrity of decentralized applications.',
    sector: 'Oracle',
    problem:
      '- Oracle Problem: Blockchain networks face challenges in connecting with external data sources, known as the "oracle problem." - Data Reliability: Ensuring reliable and accurate data for smart contracts is crucial in decentralized applications. - Decentralization and Security: Centralized oracles pose single points of failure, compromising the decentralized nature of blockchain applications.',
    solution:
      "- Solution to Oracle Problem: Chainlink provides a decentralized oracle network of independent node operators to securely fetch real-world data and deliver it to smart contracts. - Enhancing Data Reliability: Chainlink's decentralized oracle network sources data from multiple independent nodes, enhancing reliability through consensus-based aggregation. - Decentralization and Security: Chainlink's decentralized oracle network fosters a diverse ecosystem of independent node operators, increasing network security and reducing centralization risks.",
    mission:
      "Chainlink's mission is to empower builders by providing the necessary tools to take control of their economic future. It strives to pioneer a world powered by cryptographic guarantees, aiming to enable a fair and unbiased world driven by truth rather than trust, using decentralized systems",
    vision:
      'Chainlink envisions a decentralized future where hybrid smart contracts on any blockchain can securely access essential off-chain resources. As a decentralized computing platform, it seeks to power the verifiable web, bringing to life the next generation of decentralized applications through its innovation and technology',
    valueCreation:
      "Chainlink's value creation lies in its ability to bridge the gap between blockchain networks and the real world, enabling smart contracts to access reliable, verifiable, and accurate data. By providing a decentralized oracle network of independent node operators, Chainlink ensures data integrity, security, and decentralization within decentralized applications. Its value proposition centers around empowering developers to build advanced and sophisticated applications that can securely interact with external data, driving innovation and adoption across various industries.",
    revenueFrom:
      '- dApps:\n     - Chainlink BUILD Fees\n     - Revenue Sharing\n- Blockchains:\n      - Chainlink SCALE Fees',
    revenueDenominatedIn: '- Primarily in $LINK',
    revenueTo: '- Node Operators ',
    valueCaptureToken:
      '$LINK is used to pay for services on the Chainlink network and thus the Equation of Exchange suggests that the token price rises with increasing transactional volume, considering the total $LINK supply remains the same, as well as the average holding period of $LINK.',
    valueCaptureProtocol: 'Fees and rewards are entirely earned by the Service Providers, primarily the Oracle Nodes.',
    demandDrivers: [
      "Demand for Decentralized Oracles: \nWith the increasing demand for trustworthy and authenticated data within blockchain applications, there is a growing need for Chainlink's robust oracle solutions. This surge in demand for Chainlink's oracle services drives the corresponding need for $LINK tokens, which are utilized as a means of payment to access and utilize these reliable data services.",
      "Integration into DeFi Ecosystem: \nChainlink has become a fundamental component of the decentralized finance (DeFi) ecosystem. Many DeFi protocols rely on Chainlink's oracles to access external data for functions such as price feeds, lending/borrowing rates, and more. As the popularity and adoption of DeFi projects rise, the demand for $LINK tokens increases to access and utilize Chainlink's oracle services.",
      "Node Operation and Staking: \nTo become a Chainlink node operator and participate in the network, individuals need to stake a certain amount of $LINK tokens. This requirement creates demand for $LINK tokens as aspiring node operators acquire them to fulfil the staking criteria and take part in the network's decentralized oracle infrastructure.\n",
    ],
    tokenUtility:
      "Fee Token:\n$LINK serves as the primary fee token within Chainlink's ecosystem. Users utilize $LINK tokens to access and acquire valuable data from the network.\n\nStaking for Node Operations:\nTo become a Chainlink node operator and participate in the network's decentralized oracle infrastructure, individuals are required to stake a certain amount of $LINK. This stake acts as collateral and ensures the integrity and reliability of its services.\n\nCommunity Staking and Auto-Delegation:\nApart from node operators, community members also have the opportunity to stake their $LINK tokens. These staked tokens are automatically delegated to eligible node operators, further strengthening the network's decentralization and encouraging active participation from the community.\n\nCollateral for DeFi Loans:\n$LINK can be used as collateral to secure loans within the decentralized finance (DeFi) ecosystem. By pledging their $LINK tokens as collateral, individuals can access borrowing options and unlock liquidity while retaining ownership of their assets. This use case expands the utility of $LINK and enhances its role as a valuable asset within the DeFi space.",
    coingeckoApiKey: 'chainlink',
    price: 0.235645,
    marketCap: 351237848,
    fdv: 353497000,
    volume: 67989015,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Chainlink%20Tokenomics#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1utPLUWEOIJ4Exrc0I9jV0fF6rUb7XPV8%26export%3Ddownload',
    parent: '- Band Protocol\n- DIA (for financial data)\n- API3',
    strongPoints: null,
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/877/standard/chainlink-new-logo.png?1696502009',
  },
  {
    title: 'Chromia',
    ticker: 'CHR',
    shortDescription:
      'Chromia is a blockchain protocol that aims to provide a decentralized, developer-friendly platform for building dApps and games. It is built on top of a relational blockchain, which enables more flexible and powerful data handling compared to traditional blockchains. The Chromia (CHR) token is a main component for participating in dApps and games built on the Chromia blockchain. It needs for transaction fees, node running fees, staking rewards, and using CHR as a medium of exchange.',
    sector: 'Layer 1',
    problem:
      'High transaction fees and slow transactions limit the scalability and adoption of traditional blockchains.',
    solution:
      'Chromia protocol offers a decentralized platform with a relational blockchain architecture that enables fast and affordable transactions. The CHR token is crucial for participating in the ecosystem, providing staking rewards, transaction fees, and node running fees. This makes Chromia an attractive solution for developers to build decentralized applications and games.',
    mission:
      "Chromia's mission is to facilitate the creation of decentralized applications in the real world, aiming for a better, more efficient digital ecosystem. It emphasizes the use of a relational blockchain platform to simplify the development process, making decentralized technology more accessible and practical for real-world applications",
    vision:
      'Chromia envisions a reformed internet infrastructure that aligns closely with user needs, emphasizing a corruption-free, efficient public sector. It commits to developing publicly hosted application infrastructure, striving to create a user-centric digital world powered by its relational blockchain technology, a vision it has pursued since 2012',
    valueCreation:
      'Chromia offers efficient and cost-effective side-chains for decentralized applications (dApps) and games. Its competitive advantage is low transaction fees and high throughput. Users would use CHR for transaction fees like ETH does now. Games and dApp developers were attracted by the capabilities to deploy their apps on Chromia sidechains quickly and collect aforementioned fees directly from users.',
    revenueFrom: 'the sale of CHR tokens, which are used to pay for transaction fees and storage on the network.',
    revenueDenominatedIn: 'CHR tokens, which are the native currency of the Chromia blockchain.',
    revenueTo: 'Validators and node operators. None goes back to the protocol itself.',
    valueCaptureToken:
      'Chromia does not have a direct mechanism for value accrual to the token. Instead, all fees collected from users for token swaps go to dApp developers.',
    valueCaptureProtocol:
      "A percentage of transaction fees goes to the System Node Compensation Fund to cover the expenses of system node providers who don't get paid by dApps. No other value accrual mechanism exists for the Chromia protocol treasury.",
    demandDrivers: [
      'Yield Farming:\nCrypto investors seeking high-yield investments will buy and hold $CHR to participate in yield farming opportunities.',
      'Medium of Exchange:\nGamers will hold $CHR to buy in-game currencies on games built on the Chromia protocol.',
      'DApp Hosting Fees:\nDApp developers will hold $CHR to pay for hosting fees to keep their apps running on the Chromia network.',
      'Node Provider Collateral:\nNode providers will hold $CHR as collateral to validate blocks on the Chromia network.',
      'Governance and Voting:\nFuture holders of $CHR will be able to participate in governance and voting on the Chromia protocol.',
    ],
    tokenUtility:
      '$CHR\nTransaction fees: CHR is used as a means of paying for transaction fees incurred by users when they trade dApp tokens on the Chromia protocol.\nHosting fees: dApps and games directly pay node providers with CHR for hosting services they provide.\nMedium of exchange: CHR is used as a medium of exchange for buying dApp tokens on the market.\nValidator staking: CHR is required to be staked to create a validator and secure the network.\nGovernance: CHR is used as a governance token, enabling holders to participate in important decision-making processes on the network.',
    coingeckoApiKey: 'chromaway',
    price: 0.00391586,
    marketCap: 0,
    fdv: 0,
    volume: 1929.14,
    diagramUrl: 'https://storage.googleapis.com/my-bucket-bbc0e24/008c7e19-557d-4ad0-e45c-8a73db300542.jpg',
    parent:
      'Ethereum: Ethereum is a decentralized blockchain platform that allows developers to build and deploy smart contracts and dApps using its native programming language, Solidity.\nEOS: EOS is a blockchain protocol that provides a decentralized platform for developing and hosting dApps using its native programming language, C++. It is known for its high transaction speed and scalability.\nTRON: TRON is a blockchain-based operating system that allows developers to create and deploy dApps using its native programming language, Solidity. It also enables seamless interaction with other blockchain networks.',
    strongPoints:
      '- CHR token can be linked to dApps/Games as a currency and be used inside their infrastructure, increasing token demand.',
    weakPoints:
      '- No value capture mechanism\n- Mainnet yet to be\n- No clarity on token unlocks\n- Due to delays in mainnet launch lots of competitors took over the niche.\n',
    image: 'https://assets.coingecko.com/coins/images/5000/standard/Chromia.png?1696505533',
  },
  {
    title: 'Chronos',
    ticker: 'CHR',
    shortDescription:
      'Chronos is a community-owned decentralized exchange (DEX) on the Arbitrum Layer 2 (L2) network, aimed at fostering DeFi growth through sustainable liquidity incentives. It leverages an adjusted ve(3,3) model with a unique incentive structure. ',
    sector: 'DeFi',
    problem:
      "Conventional decentralized exchanges (DEXs) face issues regarding revenue accrual for governance token holders and adequate incentives for LPs. Insufficient swap/trading fees often require liquidity mining programs, which may adversely affect token long-term value. Transferring income from LPs to the DEX can lead to liquidity reduction and decreased trading volumes. Chronos aims to address the problem of the ve(3,3) model where LPs earn direct fees and token emissions, attracting mercenary agents who negatively impact the platform's total value locked (TVL) and create unfavorable trading conditions.",
    solution:
      'Chronos enables reliable digital asset trading with low fees and limited slippage. It uses a ve(3,3) model to channel transaction fees to $veCHR voters, incentivize liquidity providers with $CHR emissions, allow protocols to incentivize $veCHR voters, and sustain $CHR emissions. This structure rewards holders who lock their $CHR, ensuring in-depth liquidity. Projects can incentivize $veCHR voters, providing additional income for $veCHR lockers and efficient liquidity procurement. Chronos is proposing an incentive-aligned model, potentially increasing demand for the protocol, where DAOs stimulate their pools through bribes and direct more $CHR emissions to them.',
    mission:
      'Cronos is dedicated to building an efficient, decentralized, and open-source blockchain, focusing on supporting the creator economy through Web3 applications like DeFi and GameFi. It aims to provide a foundation for an open metaverse and empower the next billion Web3 users with self-custody of their digital assets​',
    vision:
      'Cronos envisions a scalable and user-friendly blockchain ecosystem, striving to significantly expand the Web3 community. It focuses on enabling seamless portability of apps and assets from other blockchains, while offering low transaction fees, high throughput, and quick transaction finality, thus making decentralized applications more accessible and environment-friendly',
    valueCreation:
      "The Chronos ve(3,3) system guarantees a steady cash flow to $CHR lockers from swap fees, along with voting rights, which give the power to direct $CHR emissions towards high-volume and bribed pools – a function that lets protocols to induce holders to direct more $CHR emissions toward specific pools. Interestingly, protocols can directly incentivize $CHR lockers to allocate more emissions to their own liquidity pools. When lockers are deciding on emissions, they are influenced by the fees generated and bribes offered, optimizing the gauge system and resulting in better-aligned $CHR emissions. This solution aims for improved capital efficiency and sustained liquidity for DAOs and protocols. It is also worth mentioning that Chronos isn't really a typical ve(3,3) DEX as it gets rid of the rebase function present in most ve(3,3) protocols.\n\nIn summary:\n\n- Reliable trading and UI: Chronos enables dependable digital asset trading with minimal fees and limited slippage. Its routing engine optimizes returns on trades from accessible pools. Users benefit from a nice and intuitive UI, finding it easy to interact with the platform.\n- Incentive-aligned model: Chronos proposes an incentive-aligned model where DAOs stimulate their pools through bribes and direct more $CHR emissions to them, which is a potential demand driver.\n- Direct incentives: projects can directly incentivize $veCHR voters, providing an additional income source and enabling efficient liquidity procurement without high native token emissions.\n- TVL stability: by following the Chronos ve(3,3) model in which LPs are incentivized to keep the liquidity due to the maturity adjusted mechanism.",
    revenueFrom:
      'Revenue is generated from the initial NFT sales and ongoing swap transaction fees and NFT sale royalties.\n\nChronos gathered its initial funds from selling 5,555 $chrNFTs with varying price structures. The funds from the initial sale were used for providing $CHR liquidity, bolstering the treasury, continuous development, marketing, audits, team expenses, etc. Post-initial sale, Chronos secures ongoing income from swap transaction fees (vAMM: 0.20%, sAMM: 0.04%), primarily directed to $veCHR voters and $chrNFT stakers. In addition, Chronos accrues revenue from secondary NFT sales, allocating a percentage of royalties to $chrNFT minters and stakers.',
    revenueDenominatedIn:
      'Chronos collects NFT royalties in $ETH, and swap fees are distributed to holders depending on the pool, usually in the token pair or $CHR. Bribes, another revenue stream for the protocol, can be paid with any assets supported by Chronos.',
    revenueTo: 'Treasury & chrNFT stakers',
    valueCaptureToken:
      "In the Chronos ecosystem, $CHR holders can choose to vote-escrow their tokens, obtaining $veCHR in return. These $veCHR serve dual purposes: they allow holders to participate in voting on the platform's gauges, influencing the distribution of $CHR to liquidity pools, and they also enable holders to share in the platform's trading fees. The decision to lock tokens to obtain $veCHR is equally beneficial in both asp",
    valueCaptureProtocol:
      'The treasury is instrumental in bolstering ecosystem development, and initially draws sustenance from the token allocation and a portion of the $chrNFT primary sale. A significant 60% of the funds accrued from sales is allocated towards providing $CHR liquidity and enriching the treasury at launch. The remaining 40% is earmarked for ongoing development, marketing, team expenses, among other costs. The treasury sees value accrual via a perpetual 2% inflow from all secondary NFT sales and 5% from weekly $CHR emissions.\n\nThe protocol certainly benefits from high TVL to ensure deep liquidity and low slippage so as to attract more users using the platform. For this reason, Chronos adopts a unique strategy to reward LPs. Intriguingly, LPs do not receive any trading fees, instead, they are awarded $CHR emissions subject to a voting process by $veCHR holders. The model enables liquidity providers to accrue enhanced emissions over time, whereby positions staked for longer durations garner higher $CHR percentages per epoch.',
    demandDrivers: [
      "- Arbitrum's success: Chronos' prosperity is linked to Arbitrum's activity. Traders and DAOs/protocols on Arbitrum require a DEX for liquidity, driving demand.",
      '- Locking mechanisms: by acquiring $veCHR or $chrNFT through staking $CHR, tokens in circulation may decrease, potentially raising the price if demand is high. This also grants holders governance rights, secondary bribe income, and a share of sale royalties and swap fees.\n',
      '- Low fees and user-friendly interface: Chronos attracts users due to its low transaction costs and intuitive interface. These users prefer token swaps with minimal slippage, necessitating high Total Value Locked (TVL).\n',
      '- Long-term commitment of LPs: the platform design encourages LPs to make long-term deposits, ensuring a large TVL and, therefore, promoting demand.',
    ],
    tokenUtility:
      "- $CHR (Utility Token)\nThe principal utility token for Chronos is $CHR, an ERC-20 token utilized to motivate users to add liquidity and stake their LP tokens on the platform. LPs find the token attractive as trading fees are not received directly; instead, LP tokens must be staked to obtain the right to receive $CHR emissions. The procedure of staking LP tokens results in a maturity-adjusted return model, thereby encouraging LPs to commit their liquidity for extended periods to gain maximum rewards. Consequently, this aligns Chronos' TVL with the project's long-term sustainability and health.\n\n- $veCHR (Governance Token)\nAny holder of $CHR can vote-escrow their tokens and receive an ERC-721 $veCHR in return. This grants holders the ability to vote on the platform's gauges, which regulate the distribution of $CHR incentives to Chronos' liquidity pools.\n\n$veCHR voting power declines over time, encouraging users to frequently renew their locks for ongoing participation in the Chronos ecosystem. Benefits for $veCHR holders include weekly voting for gauges to allocate $CHR emissions, governance participation, earning 90% swap fee revenue and 100% of the bribes for pools they voted for, with the remaining swap fees allocated to $chrNFT stakers.",
    coingeckoApiKey: 'chronos-finance',
    price: 0.139477,
    marketCap: 80939161,
    fdv: 80939161,
    volume: 6596336,
    diagramUrl: 'N/A',
    parent:
      'Solidly:\nSolidly is a self-optimizing decentralized exchange. It optimizes primarily for capital-efficient acquisition of healthy TVL, meaning aggregated gauges which create the high fees for veSOLID holders. In contrast to Uniswap or Curve, which return 0% or 50% of fees to $UNI or $veCRV, respectively, Solidly distributes 100% of the fees to its governance token while also trying to achieve a 100% dilution protection. Chronos is actually a fork of Solidly.\n\nVelodrome:\nVelodrome is a decentralized AMM modeled on Solidly and designed to be a public good that provides deep liquidity and low slippage to token pairs critical for the growth of the Optimism ecosystem. It aims at combine the best of Curve, Convex, and Univ2 into a cohesive AMM native liquidity layer and growth engine. ',
    strongPoints: null,
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/29622/standard/chronos_icono-01.png?1696528558',
  },
  {
    title: 'LooksRare',
    ticker: 'LOOKS',
    shortDescription:
      'LooksRare is a community-centric NFT marketplace that distinguishes itself from its main rival, OpenSea, by distributing 100% of the trading fees generated to its token stakers and incentivizes collectors and traders with its LOOKS token incentives. LooksRare incorporates LOOKS as its inherent and fungible utility token. LOOKS is specifically designed to provide the utility functions described by LooksRare and to facilitate their transfer. ',
    sector: 'NFT',
    problem: 'Traditional marketplace models lack adequate incentives to drive user participation and engagement.',
    solution:
      "LooksRare's community-first approach and token incentivization mechanisms provide a solution by incentivizing user participation and distributing protocol revenue proportionally among token stakers.",
    mission:
      'LooksRare\'s mission is to redefine the NFT marketplace by prioritizing community engagement. It focuses on rewarding active participation among traders, collectors, and creators, using a community-centric approach. The platform emphasizes rewarding its users, redistributing platform fees to stakers, and ensuring instant creator payouts, aligning with its ethos of "by NFT people, for NFT people"​',
    vision:
      'LooksRare envisions a scalable, secure, and user-centric NFT platform, leveraging cutting-edge technology for enhanced functionality. It aims to continually evolve, offering innovative interactions like collection offers and trait-specific bidding, and ensuring compatibility with Ethereum scaling solutions. This vision is rooted in delivering a versatile, robust, and secure marketplace for NFT enthusiasts',
    valueCreation:
      "One key aspect of LooksRare's value creation is, besides its consistent technical improvements and introduction of innovative features, its incentivization program for trading and listing NFTs. These incentives attract new users, keep existing ones, and also lead to an increase in trading volume. The platform's incentive for listing blue-chip NFTs has been particularly effective in attracting high-value transactions and boosting overall trading volume.",
    revenueFrom: 'Charging a 2% fee in WETH on every transaction made on the LooksRare marketplace.',
    revenueDenominatedIn: 'WETH.',
    revenueTo:
      '100% distribution proportionally among the stakers of the LOOKS token. This includes the team-, treasury- and other allocations that can stake LOOKS.',
    valueCaptureToken:
      'Trading fees generated on LooksRare are distributed proportionally among LOOKS token stakers, capturing the value created through trading volume on the platform.',
    valueCaptureProtocol: 'The LooksRare protocol captures value by staking the tokens from the treasury allocation.',
    demandDrivers: [
      "Yield generation: \nUsers holding LOOKS can earn staking rewards as a share of LooksRare's revenue and incentives. Higher revenue generates increased fees for token stakers, resulting in higher APR and attracting more users to purchase LOOKS to stake.",
    ],
    tokenUtility:
      '$LOOKS\nStaking: Committing $LOOKS to staking pools enables users to earn a proportionate share of transaction fees generated on Looksrare marketplace.\nTrading and listing rewards: users receive $LOOKS when and listing and trading NFTs. These incentives are meant to bootstrap the platform.\nGovernance: $LOOKS holders will be able to participate in governance votings in the future. \n',
    coingeckoApiKey: 'looksrare',
    price: 0.119098,
    marketCap: 64508691,
    fdv: 118397902,
    volume: 5353366,
    diagramUrl: 'https://storage.googleapis.com/my-bucket-bbc0e24/741e1487-f48f-405d-bb54-a3918dba69c8.png',
    parent:
      "Rarible - Although LookRare was one of the first community-first marketplaces incorporating a token, Rarible with its RARI token was one step ahead, so it can be assumed that LooksRare used Rarible's tokenomics as a foundation.",
    strongPoints:
      'LooksRare utilizes a straightforward Token Model and focuses on one key token utility instead of multiple smaller ones, ensuring that it is easily understandable for investors and users.',
    weakPoints:
      'In order to secure its market share without relying on continuous token incentives, LooksRare needs to further develop its competitive edge. Currently, the majority of the trading volume (95%) is due to wash trading, which is a consequence of the distribution of rewards for listing and trading.',
    image: 'https://assets.coingecko.com/coins/images/22173/standard/circle-black-256.png?1696521517',
  },
  {
    title: 'Sui',
    ticker: 'SUI',
    shortDescription:
      'Sui is a low-latency, high-throughput permissionless layer-1 chain. It focuses on horizontal scaling through “transaction parallelization,” allowing transaction processing in “parallel agreement.” Its instant transaction finality makes Sui a prime candidate for on-chain use cases like DeFi and GameFi. ',
    sector: 'Layer 1',
    problem:
      "In traditional blockchains, gas fees go up and down based on the network demand, which can result in a costly operation, even for a simple swap. Transactions are executed individually (vertical scaling), limiting the system's scalability.",
    solution:
      'Sui focuses on horizontal scaling, which lets validators scale fast and execute parallel independent transactions. The gas pricing mechanism stabilizes fees throughout each epoch, giving users low and predictable gas fees.',
    mission:
      "Sui's mission is to onboard the next billion users to Web3, focusing on advancing the global adoption of the Sui blockchain. By offering an innovative, decentralized Layer 1 blockchain that redefines asset ownership, Sui aims to streamline the development of diverse Web3 applications, enhancing both performance and complexity​",
    vision:
      "Sui envisions a Web3 ecosystem characterized by fluidity and flexibility, similar to its namesake 'water.' It aims to provide a scalable, secure, and user-friendly blockchain infrastructure, offering low-latency transactions and flexibility to developers and users, thereby fostering the growth of gaming, DeFi, and various other Web3 projects",
    valueCreation:
      "The value created by the protocol is intended to address scalability issues since it scales horizontaly reaching a very high throughput and high transaction finality. The gas pricing mechanism allows to transact at a very low fees and anchors users' expectations to a predictable gas fee throughout an epoch (24-h). Thanks to low-latency features, Sui is a perfect candidate for different use cases like GameFi, DeFi, and SocialFi. There is also a rebase option available to users to receive back any storage fees paid in the past for free storage. ",
    revenueFrom:
      'Transaction fees\nComputation fees are paid to validators/delegators for proof-of-stake participation and to prevent spam/denial-of-service attacks\nStorage fees are used to fund the storage fund, which compensates future validators for data storage costs',
    revenueDenominatedIn: '$SUI',
    revenueTo: 'Validators/delegators for staking rewards and to the storage fund for future compensation.',
    valueCaptureToken:
      '$SUI captures value in the form of staking rewards. Increased staking rewards lead to more tokens being locked, reducing supply and creating buying pressure. Higher onchain data demand leads to a larger storage fund, reducing the amount of $SUI in circulation.',
    valueCaptureProtocol:
      '$SUI coin enables future benefits and acts as a coordination element among ecosystem participants with an incentive model. Income source for validators and delegators is gas/storage fee which increases with user adoption, leading to more earnings for validators.',
    demandDrivers: [
      'Governance Rights:\nToken holders who want a say in the protocol’s development and decision-making process will buy and hold $SUI to exercise their governance rights.',
      'Staking Rewards:\nUsers who want to earn more $SUI while securing the protocol will buy and hold $SUI to participate in the Delegated Proof-of-Stake consensus mechanism.',
      'Speculative Forces:\nInvestors who recognize the team’s and investors’ credibility will buy and hold $SUI for speculative reasons.',
    ],
    tokenUtility:
      'The $SUI token serves four primary functions:\n\n- Stake and delegate to participate in the PoS consensus.\n- Used to pay for gas fees.\n- Unit of account, medium of exchange, or store of value — especially for DApps.\n- Governance and on-chain voting.\n',
    coingeckoApiKey: 'sui',
    price: 0.628672,
    marketCap: 649505064,
    fdv: 6285963996,
    volume: 64213641,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=SUI#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D133U61a_BxRuzhxN42uEa85tVJ0HPu7SZ%26export%3Ddownload',
    parent:
      "Solana: is a blockchain platform designed to host decentralized, scalable applications. Sui leverages the Move programming language, a derivative of the Rust one used by Solana.\n\nAptos: is a layer 1 blockchain that uses key elements of the former Diem blockchain and Move. Aptos uses the core Move's global storage, while Sui uses its own. Moreover, Aptos does not have a concept of resources or objects owning other resources; instead, it uses an address-centric model like Ethereum.",
    strongPoints:
      "Sui can scale horizontally due to custom Move programming language that runs with objects rather than accounts.\nThe gas pricing mechanism is designed to let validators find consensus about gas fees to be charged each epoch (24h) and to assign boosted/slashed staking rewards depending on whether the validator complied with such agreement. This process anchors users' expectations about the gas price to be paid. ",
    weakPoints:
      'Sui forgoes consensus for numerous transactions to enable multi-lane processing and remove head-of-line blocking so the sender can submit only one transaction at a time.\nCompared to other blockchains that require the implementation of just one consensus protocol, Sui needs to apply both Byzantine Consistent Broadcast to manage simple transactions and a consensus protocol to handle transactions with shared objects. As a result, Sui developers are obliged to keep a considerably larger codebase.',
    image: 'https://assets.coingecko.com/coins/images/26375/standard/sui_asset.jpeg?1696525453',
  },
  {
    title: 'Ocean Protocol',
    ticker: 'OCEAN',
    shortDescription:
      'Ocean Protocol is a blockchain-based data exchange protocol that allows users to sell and share data while maintaining ownership rights.',
    sector: 'Data Economy',
    problem:
      'Few big organizations have both massive data assets and AI capabilities that could result in high levels of control and monetization of these assets.',
    solution:
      '- Ocean Protocol is creating a decentralized ecosystem that enables secure data sharing, collaboration, and monetization through blockchain primitives, such as dataNFTs and datatokens.',
    mission:
      "Ocean Protocol's mission is to develop tools and services that facilitate the emergence of a new Data Economy. This new economy aims to empower data owners with control, maintain privacy, and catalyze the commercialization of data, including the establishment of data marketplaces",
    vision:
      'Ocean Protocol envisions a sustainable and ubiquitous data economy that democratizes AI benefits by ensuring equitable access and monetization of data. Their system aims to be simple, transparent, and aligned with core values like data liberation, privacy protection, power distribution, wealth equality, legal compliance, censorship resistance, and trustlessness. Emphasizing permissionless, rent-free operations, they aspire to create a globally useful, anti-fragile ecosystem that thrives over decades, adapting and strengthening in the face of challenges.',
    valueCreation:
      'Ocean Protocol aims to unlock the value of data as a new asset class enabling the data economy. By providing a secure, privacy-preserving platform for buying, selling, and sharing data, Ocean Protocol is helping to solve one of the biggest challenges in the AI revolution: the lack of access to high-quality, diverse data. \n\nAt the heart of the Ocean System there is a loop, called sustainability loop, which enables growth of the ecosystem. Since an increasing usage of Ocean tools and services leads to more $OCEAN being staked, this drives $OCEAN demand thus growing $OCEAN value. More usage also leads to more Network Revenue, which goes to both burning, which reduces the total supply, and funding to the OceanDAO, which is composed of workers that have the goal of improving the Ocean ecosystem, thus aiming to attract more demand.\n',
    revenueFrom: '- Fees accrued from users who access and consume data within the Ocean Protocol ecosystem',
    revenueDenominatedIn:
      '- Mostly OCEAN tokens, even though the OceanDAO also accrues revenues in other cryptoassets due to active treasury management such as liquidity provision and yield farming',
    revenueTo:
      '- OceanDAO, which receives a share of the Network Revenue and uses funds to pay out workers, whose purpose is to grow the usage of Ocean tool.\n- The protocol burns a fraction of the network revenue, reducing the circulating supply, thus driving $OCEAN price up.',
    valueCaptureToken:
      '$OCEAN is used to trade data assets on Ocean Market, enabling data monetization via datatokens. Staking $OCEAN yields $veOCEAN, leading to rewards based on user activity and curation influence.',
    valueCaptureProtocol:
      'Asset curation enhances market efficiency, increasing trading volume and interest in $OCEAN, which in turn drives value back to the protocol as staking rewards enhance its economy.',
    demandDrivers: [
      'Governance:\n- $OCEAN is needed to obtain $veOCEAN, that is used to vote on how fee income is distributed',
      'Staking:\n- $OCEAN is needed to obtain $veOCEAN, that can be staked within the data ecosystem, earning $OCEAN rewards',
      'Burn:\n- A fraction of $OCEAN tokens, 5% at the time of writing, are burned to reduce supply of circulating tokens',
    ],
    tokenUtility:
      '$OCEAN:\n- Unit of exchange: the $OCEAN token is used to publish, buy and sell data assets in the Ocean ecosystem\n\n$veOCEAN (locked $OCEAN):\n- Governance: veOCEAN is used to vote on governance proposals put forward by the Ocean DAO\n- Staking: veOCEAN can be staked on dataNFTs to earn passive and active additional rewards, in the context of the Data Farming Program',
    coingeckoApiKey: 'ocean-protocol',
    price: 0.489199,
    marketCap: 288378314,
    fdv: 689141717,
    volume: 31320298,
    diagramUrl: 'https://app.diagrams.net/?page-id=AWlRe4LpPBFtoskn5Mg3&scale=auto#G1CH1iqL48jNFA7XFNrxN4_dWNTXrGQJoL',
    parent:
      "There are a few projects in the data economy field that share similar goals as Ocean Protocol:\n\nFilecoin: Filecoin is a decentralized storage network built on top of the InterPlanetary File System (IPFS). It aims to provide a more efficient and cost-effective alternative to centralized cloud storage solutions. While Ocean Protocol focuses on data exchange and marketplaces, Filecoin focuses on decentralized storage infrastructure.\n\nStreamr: Streamr is a decentralized, peer-to-peer network for real-time data sharing and monetization. It allows users to create, trade, and consume data streams without relying on centralized intermediaries. Streamr is similar to Ocean Protocol in that it aims to unlock the value of data, but it focuses more on real-time data streams.\n\nIOTA: IOTA is a distributed ledger technology designed for the Internet of Things (IoT) and data sharing. IOTA shares Ocean Protocol's focus on data sharing, but it specifically targets the IoT sector.\n\nArweave: Arweave is a decentralized storage network that aims to provide a permanent and tamper-proof storage solution for data. While Arweave and Ocean Protocol both operate in the data storage and sharing space, they have different goals and use cases. Ocean Protocol focuses on enabling secure data exchange, monetization, and collaboration, whereas Arweave's primary objective is to provide a permanent and tamper-proof data storage solution.\n\nOcean Protocol's competitive advantage and differentiating factors include a comprehensive approach to data exchange, including sharing, monetization, and collaboration as well as the integration with artificial intelligence (AI) and machine learning (ML) tools, enabling data-driven insights and analytics.",
    strongPoints: null,
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/3687/standard/ocean-protocol-logo.jpg?1696504363',
  },
  {
    title: 'Convex Finance',
    ticker: 'CVX',
    shortDescription:
      '💡 Convex is a yield optimisation platform build on top of Curve Finance. Convex helps users attain better yield on their Curve LP positions with minimum management requirements whilst remaining liquid',
    sector: 'DeFi',
    problem:
      'Investors in Curve Finance struggle to maximize their yield due to the complex management requirements and low deposit rates.',
    solution:
      'Convex Finance protocol offers an easy-to-use yield optimization platform that automates management tasks and constantly increases deposit rates. This allows investors to achieve better yields on their Curve LP positions with minimum effort, making their investments more profitable and liquid.',
    mission:
      "Convex Finance's mission is to revolutionize DeFi by optimizing Curve Finance's yield, enhancing staking rewards for CRV holders and liquidity providers. This community-driven project aims to incentivize sustainable income streams and elevate Curve’s functionality, thereby enriching the DeFi ecosystem.",
    vision:
      'Convex Finance envisions being a pivotal element in DeFi, focusing on maximizing Curve Finance’s income and user experience. It seeks to augment liquidity and rewards on Curve, support DeFi community growth through grants, and foster community-driven governance, thus propelling innovation and expansion in the DeFi sector.',
    valueCreation:
      "Convex Finance simplifies yield farming on Curve Finance by automating yield optimization and providing a user-friendly interface. Users can maximize returns on LP positions with minimal management while maintaining liquidity. Convex's popularity lies in its ability to generate high yields with low risk, making it attractive for novice and experienced investors. It's an essential platform for optimizing returns in decentralized finance.",
    revenueFrom:
      '17% fee cut from yield optimization solutions for Curve and Frax, paid in whatever the liquidity positions are denominated in.',
    revenueDenominatedIn: 'whatever the liquidity positions are denominated in.',
    revenueTo:
      'CVX and derivatives stakers, who receive all fees, and CVX holders, who receive bribes in the form of assets to direct liquidity towards certain pools. No revenue goes to the treasury.',
    valueCaptureToken:
      '$CVX captures value through its use in governance (veCRV) and yield optimization services (cvxCRV). Users must lock CVX to obtain vlCVX and receive bribes, creating demand for the token. CVX holders also receive yield from staking.',
    valueCaptureProtocol:
      'Convex Finance takes a 17% fee from its yield optimization service, which is paid to stakers. There is no treasury allocation for these fees, they simply go to CVX and derivatives stakers.',
    demandDrivers: [
      'Underlying $CRV demand\n$CVX demand is closely tied to $CRV demand, as $CVX holders receive boosted rewards for staking $CRV on Convex. Thus, the stronger the demand for $CRV, the higher the demand for $CVX.',
      'Protocol bribes\nConvex offers yield-boosting protocol bribes to $CVX holders, providing an additional incentive to hold the token. As long as these bribes remain attractive and competitive, they should continue to drive demand for $CVX.',
    ],
    tokenUtility:
      '$CVX\nPassive income: Users can earn passive income by staking their $CVX tokens and receiving a portion of the trading fees generated on the platform.\nLiquidity mining: $CVX holders can participate in liquidity mining programs and earn additional tokens as a reward for providing liquidity.\nGovernance: $CVX holders have voting rights and can participate in the decision-making process for the development and direction of the platform.\n\n$cvxCRV\nIncreased liquidity: Users can lock their CRV tokens to mint $cvxCRV, which can then be traded on decentralized exchanges, increasing the liquidity of the token.\nLeverage: By minting $cvxCRV, users can gain leveraged exposure to CRV without having to buy',
    coingeckoApiKey: 'convex-finance',
    price: 3.89,
    marketCap: 319152087,
    fdv: 386101455,
    volume: 4670818,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=CVX%20diagram.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1ms9OiqLQVAuIFth5F84Gv5emlmEtq6tY%26export%3Ddownload',
    parent:
      'Yearn Finance: A decentralized finance (DeFi) protocol that provides yield optimization and aggregation services for cryptocurrency investors.\nAura Finance: A DeFi platform that offers yield farming and staking services to users, with a focus on minimizing risk and maximizing returns.',
    strongPoints:
      'Convex has cornered CRV market by acquiring majority in the veCRV voting power (~56%).\n~75% of supply is staked and ~73% of supply is vote locked, this shows that the system is working and users see and incentive in staking/locking.\nBribes are a great source of passive yield which are not emissions and turn CVX into a yield bearing asset with positive cash flow for users who lock. ',
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/15585/standard/convex.png?1696515221',
  },
  {
    title: 'dYdX',
    ticker: 'DYDX',
    shortDescription:
      'dYdX is a semi-decentralized trading platform that allows users to trade perpetuals with up to 10x leverage. The platform offers a non-custodial trading experience with low fees and a high degree of transparency.',
    sector: 'DeFi',
    problem:
      'Centralized exchanges pose security risks and lack transparency, while current decentralized exchanges lack the necessary features for advanced trading such as perpetuals and leverage trading.',
    solution:
      'dYdX addresses these issues by offering a semi-decentralized platform that supports perpetuals and leverage trading. This allows for greater transparency and security while still providing advanced trading features.',
    mission:
      "dYdX's mission is to democratize access to financial opportunity. As a leading decentralized exchange (DEX) platform, it offers a range of financial instruments like perpetuals, margin, spot trading, lending, borrowing, and making bets on future prices of popular cryptocurrencies, focusing on perpetuals and catering to advanced traders",
    vision:
      'dYdX envisions a DeFi ecosystem where financial opportunities are accessible to all, supported by an empowered community and decentralized governance. Their commitment to growth and innovation is mirrored in their focus on long-term, impactful goals, decentralization through transparency, and a drive for rapid progress.',
    valueCreation:
      "The value created by dYdX is significant, providing users with access to perpetuals and leverage trading on a semi-decentralized platform. This allows traders to profit from market movements without owning the underlying asset. With a semi-decentralized approach, dYdX offers secure and transparent trading where users control their private keys while benefiting from the platform's liquidity and infrastructure.",
    revenueFrom: 'Fees are charged on all trades made on the platform.',
    revenueDenominatedIn: 'The currency of the traded asset.',
    revenueTo:
      'Centralised dYdX Trading Inc. Token rewards and fee reductions are offered to traders holding dydx tokens, but the current revenue does not go to a $DYDX token-governed entity.',
    valueCaptureToken:
      'None. The dydx protocol does not use its token to capture the value it creates through supply and demand mechanisms.',
    valueCaptureProtocol:
      'The protocol currently captures value through revenue generated from fees, which accrue to the centralized dYdX Trading Inc. entity. There is no protocol treasury or $DYDX token-governed entity that captures this value.',
    demandDrivers: [
      'Discounted trading fees:\n$dydx token holders receive discounted trading fees on the dydx trading platform, providing an incentive to purchase and hold the token for frequent traders.',
      'Speculation on revenue accruing to DAO-governed treasury with V4:\nThe upcoming V4 upgrade will introduce a DAO-governed treasury, enabling $dydx token holders to speculate on potential revenue generated from platform usage and governance decisions.',
    ],
    tokenUtility:
      "$DYDX\n\nReduced trading fees: Holders of $DYDX tokens can access reduced trading fees on the platform, providing a financial incentive to hold and use the token.\nGovernance participation: $DYDX token holders have voting rights and can participate in decision-making processes related to the protocol's development and direction.\nStaking rewards: Staking $DYDX tokens can provide additional $DYDX tokens as a reward, creating an opportunity for investors to earn a passive income while supporting the protocol.",
    coingeckoApiKey: 'dydx',
    price: 3.45,
    marketCap: 619447409,
    fdv: 3436350114,
    volume: 220092947,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Tokenomics101%20dydx.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1cqj6lc92jollTwRDGR6ighl5F54K-ysr%26export%3Ddownload',
    parent:
      'Perpetual Protocol - a decentralized perpetual futures trading platform.\nSerum - a decentralized exchange built on Solana blockchain with trading pairs including perpetuals and futures.\nMCDEX - a decentralized derivatives trading platform built on Ethereum with support for perpetuals and other derivative products.',
    strongPoints: 'Largest decentralised leverage/perpetuals exchange',
    weakPoints:
      'Decent revenue compared to competitors, but tied to token emission as incentive\nLarge unlock coming in January 2023\nCurrent circulating supply very low (10%), requiring a lot of growth to create demand for the token.',
    image: 'https://assets.coingecko.com/coins/images/32594/standard/dydx.png?1698673495',
  },
  {
    title: 'Ethereum',
    ticker: 'ETH',
    shortDescription:
      'Ethereum is a decentralized, open-source blockchain platform that enables developers to build and execute smart contracts and decentralized applications (DApps). It uses its native cryptocurrency, Ether (ETH), to facilitate transactions and incentivize participants to maintain the network. Ethereum also allows for the creation of new tokens and the deployment of custom logic on the blockchain, making it a powerful tool for building decentralized applications and services.',
    sector: 'Layer 1',
    problem:
      "Bitcoin's scripting language was too limited to support the creation of complex smart contracts and dApps.",
    solution:
      'Ethereum introduced a Turing-complete programming language and decentralized platform, enabling developers to build and run smart contracts and dApps with greater functionality and flexibility on its blockchain.',
    mission:
      'Driven by the mission to decentralize the web, Ethereum aims to redefine the future of technologies, democratizing the web as never seen before. At its core, Ethereum is a decentralized platform that enables developers to build and deploy smart contracts, which are self-executing agreements with predefined conditions',
    vision:
      "Ethereum's vision is to create a blockchain that is scalable, secure, and decentralized. This vision addresses the scalability trilemma, a fundamental challenge in blockchain technology, which involves balancing scalability, security, and decentralization",
    valueCreation:
      'Payment network and foundation to run smart contracts. Think DAO, dApp or NFT\n\nThe more users transact, the more the project gains in value - more people everyone can transact with.\n\nThe ecosystem gains with each new user, application and transaction',
    revenueFrom: 'Transaction fees are generated by users of the network.',
    revenueDenominatedIn: 'ETH is the native cryptocurrency of the Ethereum blockchain.',
    revenueTo:
      'Validator and node operatos. Stakers also receive inflation for staking and securing the network. The percentage varies depending on the current level of staking participation but is currently around 4-5% annually.',
    valueCaptureToken:
      'ETH is utilized for transaction fees on the Ethereum network. With Ethereum 2.0, staking ETH contributes to network security and earns yield, increasing its intrinsic value as network usage grows.',
    valueCaptureProtocol: 'None',
    demandDrivers: ['Demand mainly comes from the gas fee to be paid in $ETH.'],
    tokenUtility: 'Gas. $ETH is used to pay for transaction on the network.',
    coingeckoApiKey: 'ethereum',
    price: 2169.12,
    marketCap: 260599407345,
    fdv: 260599407345,
    volume: 14891769092,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=tokenomics_ethereum2#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1VU85dnm3oz7DS6nAkQHUfKIiO-PLd_YO%26export%3Ddownload',
    parent:
      'Cardano - A decentralized blockchain platform that enables the creation of smart contracts and decentralized applications.\nPolkadot - A multi-chain network that allows different blockchain networks to connect and communicate with each other.\nSolana - A high-performance blockchain platform that uses a unique consensus algorithm to enable fast and secure transactions at scale.',
    strongPoints:
      'Ethereum still is the largest smart contract chain, with lots of innovation and on chain activity.\nOther scenarios such as Gaming, NFT, Identity and DAOs are emerging aside from DeFi',
    weakPoints:
      'Ethereum is working on performance improvements which could reduce gas fees. The impact of this on demand for gas is uncertain.',
    image: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
  },
  {
    title: 'GMX',
    ticker: 'GMX',
    shortDescription:
      'GMX is a decentralized spot and perpetual exchange that supports low swap fees and zero price impact trades. Perpetual contract traders can use up to 30X leverage on the GMX exchange. Users benefit from a unique token incentive mechanism to compound their yield by staking tokens and participating in the protocol revenues.',
    sector: 'DeFi',
    problem:
      "High swap fees and price impact on centralized spot and perpetual exchanges limit traders' profitability. Lack of incentives and limited leverage options also hinder users' yield.",
    solution:
      'GMX offers a decentralized exchange with low swap fees and zero price impact trades, supporting up to 30X leverage for perpetual contract traders. Users can earn compound yield by staking tokens and participating in protocol revenues through a unique token incentive mechanism.',
    mission:
      "GMX's mission is to offer a decentralized, permissionless perpetual swap and spot exchange for cryptocurrency trading. It emphasizes providing a trading experience similar to centralized exchanges but maintains decentralized operation, allowing traders to trade directly from their personal wallets. GMX focuses on delivering a better trading experience with low swap fees and zero-price impact trades, utilizing its native multi-asset pool, GLP, for liquidity and Chainlink Oracles for dynamic pricing",
    vision:
      'The vision of GMX is to evolve into an even more comprehensive and user-friendly decentralized exchange (DEX) for on-chain leverage trading. Through its internal governance process, GMX DAO aims to enhance its platform to offer more complete services and a superior trading experience for its users​',
    valueCreation:
      'GMX protocol offers decentralized trading with low swap fees, zero price impact trades, and enhanced security. Traders can leverage up to 30X for perpetual contracts and stake tokens to earn more yield, creating a sustainable ecosystem. GMX is a valuable alternative to centralized exchanges, providing unique token incentives, increased security, and lower fees for optimized trading experience.',
    revenueFrom:
      'Swap fees: 0.2%-0.8%\nTrading fees: 0.1% of position size\nExecution fees: collected in $ETH/$AVAX\nLiquidation fees: depends on collateral type\nBorrow fees: varies with GLP pool utilization',
    revenueDenominatedIn: '$ETH/$AVAX and other applicable currencies',
    revenueTo: "Token holders who receive all platform fees\nIncreased protocol revenues increase token holders' value",
    valueCaptureToken:
      '$GMX holders benefit from governance rights, allowing them to have a say in community funds. Additionally, $esGMX can be converted to $GMX after 1 year, providing long-term value by lowering circulating supply.',
    valueCaptureProtocol:
      'GMX protocol generates revenue in $ETH/$AVAX, which is allocated to the treasury. This relieves $GMX emissions and provides a stable funding source for future development.',
    demandDrivers: [
      'Token holder incentives\n$GMX and $esGMX token holders are incentivized by governance rights and rewarded in $esGMX, MPs, and 30% of the protocol fees. This provides an attractive opportunity for investors seeking a long-term hold and passive yield.',
      'Liquidity provider rewards\nLiquidity providers are incentivized to supply collateral and increase GLP pool size by receiving $esGMX and earning 70% of the protocol fees. This provides a lucrative opportunity for yield farmers looking for short-term gains. Overall, the staking mechanism creates a strong demand for both $GMX and $esGMX tokens.',
    ],
    tokenUtility:
      '$GMX\nStaking: Holders of $GMX can stake their tokens and earn 30% of the protocol fees generated by the GMX protocol.\nBoost Yield: Stakers of $GMX can also earn $esGMX and Multiplier Points (MPs) to further boost their yield.\nVoting Rights: Owning $GMX grants voting rights to participate in the governance of the GMX protocol.\n\n$esGMX\nStaking: Holders of $esGMX can stake their tokens and further compound their $GMX staking yield.\nVesting: $esGMX can be vested and converted to $GMX after one year, allowing for long-term investment and governance participation.\n\n$GLP\nAutomatic Staking: $GLP (LP token) holders are automatically staked and earn 70% of the protocol fees generated by the GMX protoc',
    coingeckoApiKey: 'gmx',
    price: 52.63,
    marketCap: 486803205,
    fdv: 486803205,
    volume: 12597818,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=GMX_diagram.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D10q-vENkc36CLNxTjNyWA8ez5IHsjk8K3%26export%3Ddownload',
    parent:
      'Perpetual Protocol: a decentralized trading platform that enables users to trade perpetual contracts with up to 10x leverage.\nGains Network Protocol: a decentralized finance (DeFi) platform that allows users to earn passive income through staking, liquidity providing, and yield farming.',
    strongPoints:
      'GMX allows future perpetual trading whose market size exceeds the spot market. This would lead to higher demand for such service.\nThe overall staking mechanism enables users to compound yield uniquely and ensures that a considerable token supply is constantly locked, leading to supply shortage and scarcity.\nThe $GLP mint/burn mechanism connotes a non-inflationary tokenomics model.',
    weakPoints:
      'The team is anonymous, and the DAO is far from true decentralization.\nThe GLP pool is a counterparty to traders. If traders profit » LPs profit, the GLP pool could get drained and suffer low liquidity, so the protocol should allow more yield to be attractive for LPs. Often, more yield means more token inflation at the end, even though the GMX token mechanism leads to controlled inflation.',
    image: 'https://assets.coingecko.com/coins/images/18323/standard/arbit.png?1696517814',
  },
  {
    title: 'Goracle Network',
    ticker: '$GORA',
    shortDescription:
      'The Goracle network is a decentralized oracle provider secured by the $GORA token and aims to provide the Algorand ecosystem with off-chain data. By harnessing its technology and clever design, Goracle provides decentralized applications with reliable, secure, and high-throughput data that can be used to unlock a plethora of use cases on the blockchain.',
    sector: 'Oracle',
    problem:
      'Blockchains are isolated from the rest of the world. Decentralized applications that employ real-world data need an oracle that can provide data quickly, without sacrificing the reliability or security of that data.',
    solution:
      '-By matching the security of its underlying blockchain and harnessing its technology, the Goracle network provides decentralized applications and builders with real-world data that allows them to build endless applications and onboard the next generation of blockchain users and use cases.',
    mission:
      "Goracle's mission is to advance the state-of-the-art in oracle and blockchain reliability, safety, and performance. The project aims to provide a flexible and modular oracle architecture, which supports frequent upgrades and adopts the latest technology advancements. This architecture is designed to offer first-class support for new and emerging use cases, ensuring the infrastructure can adapt and evolve to meet the growing demands of the blockchain ecosystem",
    vision:
      "Goracle's vision is to deliver an Oracle solution that drives mainstream adoption of web3 and Algorand. They seek to empower an ecosystem of decentralized applications to solve real-world problems. The vision encompasses creating a decentralized, secure, and scalable network, governed and operated by the community. Goracle aims for a network that can scale its resources to meet growing infrastructure demands and seamlessly upgrade without disrupting users, maintaining a focus on security without compromising privacy or decentralization",
    valueCreation:
      'The value created by Goracle lies in its ability to provide developers and decentralized applications with real-world data that is fast (high throughput), reliable (accurate), and secure (tamper-proof). This real-world data can range from weather to event outcomes to price feeds, allowing a myriad of use cases to be fulfilled, such as borrowing and lending, insurance, and sports betting on the blockchain, among other things.',
    revenueFrom: 'Consumers buying off-chain data and computations with $GORA tokens.',
    revenueDenominatedIn: "The transactions are conducted in the protocol's native $GORA tokens.",
    revenueTo:
      'Node runners/operators providing data or computations, proportionate to their staked and delegated $GORA tokens.',
    valueCaptureToken:
      '-The network generates fees in $GORA from consumers that require real-world (off-chain) data and computation and passes them onto node runners/operators and their delegators.\n-Node runners/operators also receive $GORA rewards from the protocol for behaving in line with the protocol rules and following consensus.',
    valueCaptureProtocol: '-None; all fees generated get passed onto node runners/operators and their delegators.',
    demandDrivers: [
      '-An increase in consumers seeking reliable, secure, and high-throughput off-chain data from a decentralized oracle provider like Goracle would drive demand for $GORA, as the token is required to pay for this service.',
      '-An increase in the number of consumers requiring computational power from the network would drive demand for $GORA, as the token is required to pay for this service.',
      '-An increase in fees generated (by providing data or computation) by the network would make running a node more desirable and would thus drive demand for $GORA, as the token is required to setup a node.',
      '-An increase in fees generated (by providing data or computation) by the network would make delegating $GORA tokens to a node runner/operator more desirable and would thus drive demand for $GORA, as the token is required to delegate stakes in the network.',
    ],
    tokenUtility:
      "$GORA's utility:\n-Pay for real-world (off-chain) data and computation on the Goracle network.\n-Governance over the network.\n-Staking to run a node runner/operator and receive rewards (from the protocol and consumers).\n-Delegation to a node runner/operator to receive rewards (from the protocol and consumers).\n-Provide on-chain liquidity.",
    coingeckoApiKey: 'goracle-network',
    price: 0.301763,
    marketCap: 1888900,
    fdv: 1888900,
    volume: 67003,
    diagramUrl: 'https://storage.googleapis.com/my-bucket-bbc0e24/047a1960-3b75-4d80-df43-169e4ffc94b8.png',
    parent:
      'Similar projects include Chainlink, Uma, Band, Tellor, and other oracle providers; however, most of these projects provide oracles on EVM-compatible and equivalent chains. Goracle is providing oracles on the Alogrand blockchain, which is neither EVM-compatible nor equivalent.',
    strongPoints: null,
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/30945/standard/IMG_3807.jpeg?1696529784',
  },
  {
    title: 'Helium',
    ticker: 'HNT',
    shortDescription:
      'Helium is a blockchain project that aims to leverage a decentralized network of hotspots to provide wireless connectivity to internet users and the Internet of Things everywhere in the world.\nThe tokens of this protocol are HNT (earned by hotspots for providing connectivity and securing the network) and Data Credits (USD-pegged coins minted by users through HNT-burn to use the network)',
    sector: 'DeWi',
    problem:
      'Limited wireless connectivity in many areas and high costs for accessing the internet and using IoT devices.',
    solution:
      "Helium's decentralized hotspot network allows individuals to provide connectivity and earn HNT tokens, while users can access the network by burning HNT to mint Data Credits, making it affordable and accessible. This encourages network growth and creates a self-sustaining ecosystem for wireless connectivity and IoT devices worldwide.",
    mission:
      "Helium's mission is to rapidly build open, affordable, and secure global wireless networks, owned and operated by the people. This aims to unlock a new future of connected applications and use cases, breaking away from the traditional status quo​",
    vision:
      "Helium's vision is democratizing network access and empowering individuals to contribute to and benefit from a global connectivity infrastructure, focused on innovation, community empowerment, and creating new possibilities in the realm of connected technology.",
    valueCreation:
      "Helium protocol's value lies in its ability to offer a decentralized wireless network, accessible worldwide without traditional internet service providers. Hotspot contributors earn HNT tokens, while Data Credits enable device internet access. This benefits all participants, creating a self-sustaining ecosystem. Helium has the potential to revolutionize wireless connectivity for the Internet of Things.",
    revenueFrom: 'Transaction fees for Data Credits (HNT), sold by the network to users.',
    revenueDenominatedIn: 'HNT, is the native cryptocurrency of the Helium network.',
    revenueTo:
      'Hotspot operators who provide coverage/verification (65%) and security token holders (35%) receive a portion of mined tokens. The team and investors receive a decreasing ratio of mined tokens over time.',
    valueCaptureToken:
      "HNT tokens are rewarded to Hotspots, validators, and investors as an incentive for contributing to the Helium network. The token's value is determined by supply and demand dynamics, with its price reflecting the market's perception of the network's value.",
    valueCaptureProtocol:
      "Helium captures value through Data Credits (DCs), which are used to pay for network usage. As the demand for IoT connectivity grows, the protocol's treasury receives a portion of the DC fees, allowing it to capture value from the network's growth. ",
    demandDrivers: [
      'Data transfer and Hotspot creation:\nUsers of the Helium network require HNT to create Hotspots and use Data Credits for data transfer. The burning of HNT to create Data Credits ensures a constant demand for the token.',
      'Validator staking:\nHNT holders can become validators of the network, earning up to 6% of the emitted tokens. This incentivizes HNT holders to stake their tokens, reducing the circulating supply and increasing demand.',
    ],
    tokenUtility:
      '$HNT\nWireless coverage providers are rewarded with HNT for contributing to the network by hosting hotspots that provide wireless coverage.\nHNT can also be used for transactions on the Helium network, such as sending HNT from one wallet to another.\n\n$DCs\nThe Helium network allows for the transfer of data from sensors to servers, which can be used for various applications such as IoT devices and asset tracking.\nHNT can be used for staking to become a validator on the Helium network, which helps to secure the network and earn rewards.\nUsers can add hotspots to the Helium network to provide wireless coverage and earn HNT as a reward.\nAsserting hotspot location is also possible with the Helium ne',
    coingeckoApiKey: 'helium',
    price: 2.97,
    marketCap: 427518165,
    fdv: 662359186,
    volume: 3543014,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=HNT_diagram.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D11uwHzRowRIzxisB5uiRBH2YJQNkGiDqn%26export%3Ddownload',
    parent:
      'Nodle (NODL) - A decentralized wireless network designed to connect IoT devices and provide location-based services.\n',
    strongPoints:
      'The token rewards the people securing and creating value for the protocol and users pay for that added value. There seems to be a good balance. The potential issue of reduced liquidity over time due to the burning of HNT is addressed by the “Net Emission” mechanism which was introduced by the HIP20.',
    weakPoints:
      'It seems that to use the network, you need to directly interact with the protocol which makes the entry for users not as easy as it could be if the solution was interfaced with existing mainstream products. Steps in that direction are being taken by the protocol such as partnerships with operators. ',
    image: 'https://assets.coingecko.com/coins/images/4284/standard/Helium_HNT.png?1696504894',
  },
  {
    title: 'Lido Finance',
    ticker: 'LDO',
    shortDescription:
      'Lido is a liquid staking platform for popular cryptocurrencies such as ETH, MATIC, DOT, KSM, and SOL. It enables users to earn staking rewards while still being able to use their tokens in DeFi applications. Lido offers staked tokens such as stETH and stMATIC, and users can participate in governance through the LDO token.',
    sector: 'DeFi',
    problem:
      'The Proof of Stake (POS) staking ecosystem is hindered by illiquidity, making it hard for stakers to use their assets in DeFi.',
    solution:
      "Lido protocol offers a secure and liquid staking solution, allowing users to stake assets in various cryptocurrencies and use them in DeFi as staked tokens. This allows stakers to earn rewards and utilize their assets in other protocols. The LDO token also allows for governance participation, empowering the community in the protocol's development.",
    mission:
      "Lido's mission is to simplify and decentralize staking, making it accessible to as many people as possible. They focus on being Ethereum-first but also support several other Proof of Stake blockchains, governed by Lido's community through decentralized governance using the Lido DAO's LDO token",
    vision:
      "Lido's vision is a more inclusive and user-friendly blockchain ecosystem. This vision encompasses broadening participation in blockchain technologies and enhancing the accessibility and usability of staking processes for diverse user groups.",
    valueCreation:
      "Lido Finance Protocol's value is in its liquid staking solution for popular cryptocurrencies like ETH, MATIC, DOT, KSM, and SOL. Staking and DeFi benefits are possible with staked tokens, and LDO token provides governance rights. This creates a sense of community and decentralization, making Lido ideal for maximizing staking rewards while retaining liquidity and participating in decentralized governance.",
    revenueFrom: 'Collecting 10% of staking rewards generated by validators on PoS chains, e.g., Ethereum',
    revenueDenominatedIn: 'The native token of the PoS chain, e.g., $ETH for Ethereum',
    revenueTo: '5% goes to the treasury and 5% goes to the validators who generate the staking rewards.',
    valueCaptureToken:
      "The LDO token doesn't offer much utility beyond governance rights. So the value will only be reflected in as much as the users' desire for a say in the direction of the protocol.",
    valueCaptureProtocol:
      'Lido Finance captures value by allowing users to stake their PoS tokens without lockup periods, generating revenue from staking rewards. 5% of the revenue is added to the treasury, ensuring the sustainability and development of the protocol.',
    demandDrivers: [
      'Governance:\nLDO holders have a say in the treasury and protocol decisions.',
      'stETH liquid staking derivative:\nstETH is a liquid staked version of ETH which can be used in DeFi and earn staking rewards and can participate in yield farming opportunities.',
    ],
    tokenUtility:
      '$LDO\nGovernance token that provides voting rights and control over Lido Finance parameters and treasury management\nEnables community-driven decision-making regarding the future of Lido Finance\n\n$stETH\nA liquid staking derivative of ETH that allows holders to participate in staking rewards without sacrificing liquidity\nCan be used as collateral for loans and other DeFi applications, unlocking additional earning opportunities\nAllows investors to earn rewards from staking ETH while avoiding the complexity and technical requirements of running a validator node themselves\nCan be traded on various decentralized exchanges, providing flexibility and liquidity for holders.',
    coingeckoApiKey: 'lido-dao',
    price: 2.45,
    marketCap: 2175173772,
    fdv: 2443879380,
    volume: 57075181,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Lido%20Finance.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1IozehASoXnOUhyXrISf3cv6nO2L5usTO%26export%3Ddownload',
    parent:
      'Rocket Pool - A decentralized Ethereum proof-of-stake infrastructure service that allows anyone to earn rewards on their ether holdings without needing to run their own validator node.',
    strongPoints:
      'Lido has the first-mover advantage. Currently, 30% of the total ETH staked is staked by Lido. stETH token is in the top 15 tokens by market cap, and there will be more use cases for it in the future. ',
    weakPoints:
      'Adding most of the tokens into circulation in less than two years and not offering much in terms of utility has a negative impact on the token’s value.',
    image: 'https://assets.coingecko.com/coins/images/13573/standard/Lido_DAO.png?1696513326',
  },
  {
    title: 'MakerDAO',
    ticker: 'MKR',
    shortDescription:
      'Maker is a permissionless, multi-asset, overcollateralized smart contract lending platform based on the Ethereum blockchain. It allows users to take out decentralized loans by locking-in collateral in exchange for $DAI, an algorithmic stablecoin.',
    sector: 'DeFi',
    problem:
      'Traditional lending platforms require extensive paperwork and credit checks, making loan access difficult. Centralized systems carry risks of corruption and failure, causing losses for lenders and borrowers.',
    solution:
      "Maker's decentralized lending platform eliminates the need for credit checks or paperwork, reducing barriers to loan access. Overcollateralization and $DAI stablecoin reduces default risks, while permissionless access and Ethereum blockchain promote transparency and security.",
    mission:
      'The mission of MakerDAO is to create an unbiased currency for the world, designed to be universally accessible and usable across the globe.',
    vision:
      'The mission of MakerDAO is to establish a decentralized and stable financial ecosystem. This vision includes providing a stable digital currency (Dai) that offers financial stability and freedom from traditional banking systems, making financial services more inclusive and accessible globally.',
    valueCreation:
      "The value created by Maker lies in its decentralized lending platform on Ethereum, offering users permissionless, multi-asset loans through smart contracts. Maker's over-collateralization ensures secured loans and reduced default risks. Its stablecoin, $DAI, provides a stable store of value. Users can access credit transparently and securely, bypassing traditional financial institutions.",
    revenueFrom:
      'Interest revenues (Stability Fees) from loans, liquidation penalties from liquidated vaults, stablecoin trading fees from the Peg Stability Module (PSM)',
    revenueDenominatedIn: 'DAI stablecoin, MKR governance token',
    revenueTo:
      'MKR token holders (surplus auction), Protocol Treasury (debt auction), and MKR burn (excess revenue) with varying percentages determined by governance votes.',
    valueCaptureToken:
      "$MKR holders benefit from governance rights, allowing them to manage the protocol's treasury, stability fees, DSR, PSM, collateral liquidation price, and asset collateralization ratio. In addition, if the protocol makes sound lending decisions, $DAI received from borrowers can be converted into $MKR and burnt, generating profits that accrue to $MKR holders.",
    valueCaptureProtocol:
      "The Maker Protocol earns income from stability fees charged on $DAI loans and collects liquidation penalties. These revenues are used to fund protocol expenses and back the $DAI stablecoin. Any excess income is added to the protocol's treasury, which can be used to support future growth ",
    demandDrivers: [
      "Governance:\n$MKR token holders are incentivized to buy and hold the token due to its governance rights, giving them the power to vote on key decisions that affect the Maker protocol's direction.",
      "Token value accrual:\nKeepers are another key player in driving demand for $MKR tokens. In a surplus auction, keepers compete in an increasing bid auction to receive a fixed quantity of $DAI, incentivizing them to acquire $MKR. In a debt auction, keepers try to acquire $MKR at a discounted price to quickly cover the protocol's debt, further driving demand for the token due to the mapping of value. ",
    ],
    tokenUtility:
      '$DAI\nBorrowing: Users can borrow $DAI by overcollateralizing digital assets. They pay a stability fee for borrowing and receive back collateral when repaying their debt. A liquidation auction may occur if the collateral value drops below the liquidation price.\nYield: $DAI can also be locked to generate yield (DSR)\n\n$MKR\nVoting: $MKR holders own voting rights on the Maker Protocol.\nMinting/Burning: If a debt auction occurs (loan>collateral), $MKR is minted. Else $MKR is burnt in the case of surplus auction (loan<collateral).',
    coingeckoApiKey: 'maker',
    price: 1541.02,
    marketCap: 1414947243,
    fdv: 1506086443,
    volume: 63447396,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Maker%20DAO.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1_C6_9tbB6QOLoX60sORURUpgQgx-p2TI%26export%3Ddownload',
    parent:
      'Compound Finance - a decentralized lending platform that allows users to lend and borrow a variety of cryptocurrencies.\nAave - a decentralized liquidity protocol that allows users to earn interest on deposits and borrow assets on a peer-to-peer basis.\nSynthetix - a decentralized platform that enables the creation of synthetic assets that track the value of real-world assets, allowing users to gain exposure to a variety of assets without actually holding them.',
    strongPoints:
      '- Strong brand reputation due to the first-mover advantage of being one of the earliest and most successful DAO in the web3 space\n- RWA enabled as collateral type\n- Token holders benefit from managing billions in their treasury\n- $DAI management which is the most recognizable algorithmic stablecoin\n- Great decentralization',
    weakPoints:
      '- Poor $DAI lending decisions lead to an additional $MKR minted to cover losses. It can depreciate $MKR price \n- $DAI is more volatile than other non-algorithmic stablecoins\n- $MKR has only governance purpose for token holders\n- Burning $MKR decreases circulation supply, but it only redistributes current value among a smaller group of people since the overall value of the system has not changed\n- RWAs expose $DAI holders to external market risk. Refer to Maker dashboard in the resources section',
    image: 'https://assets.coingecko.com/coins/images/1364/standard/Mark_Maker.png?1696502423',
  },
  {
    title: 'Olympus Protocol',
    ticker: 'OHM',
    shortDescription:
      'Olympus Protocol, and by extension its token OHM, aims to take on the role of global decentralized reserve currency by conducting an autonomous and dynamic monetary policy that allows it to fulfil the requirements of traditional reserve currencies. OHM is backed by crypto-native assets and it is designed to achieve relative stability and scalability over time, effectively bridging the gap between FIAT-pegged stablecoins and volatile crypto assets.',
    sector: 'DeFi',
    problem: 'Bridging the gap in DeFi for decentralized reserve currency & stable value without direct pegging.',
    solution:
      'Olympus issues OHM, backed by asset basket, with treasury ensuring $1 backing per OHM via Range-Bound Stability mechanism.',
    mission:
      'Olympus aims to build a programmatic policy-controlled currency that:\n\nPreserves purchasing power with long-term price predictability.\nMaintains reliable liquidity across decentralized exchanges.\nServes as a unit of account, pairing with various decentralized assets.\nActs as a trusted asset for collateralizing other assets or for protocol treasuries.\nRemains fully decentralized and community-controlled.\nOffers financial flexibility, allowing users to borrow against their money',
    vision:
      "Olympus DAO's vision is centered on innovating within the DeFi space to provide stable, reliable, and user-empowering financial tools and services.",
    valueCreation:
      'Olympus provide users with an asset that is less volatile than most of other cryptoassets, without having to rely on FIAT-backed stablecoins. This creates value for institutional and retail investors that are looking for safe heaven assets during risk-off periods. Olympus also supports early-stage DAOs through their incubator program and grants program. Lastly, Flex Loans allow protocols to grow their protocol-owned liquidity through an interest free loan using gOHM as collateral to borrow OHM.',
    revenueFrom:
      'Deployment of treasury assets into other DeFi protocols generates revenue through yield and bribes. Incubator program investments also provide a source of revenue.',
    revenueDenominatedIn: 'Revenue is denominated in various cryptocurrencies, including but not limited to OHM.',
    revenueTo:
      'Revenue is added to the protocol treasury, where it can be used to fund additional investments, grow liquidity, and support the ongoing development of the protocol.',
    valueCaptureToken:
      'OHM token maps the value created by the supply and demand dynamic, as its value rises or falls with the demand for OHM. However, the token only partially captures the value through an increased claim on backing/treasury assets.',
    valueCaptureProtocol:
      'Olympus captures value through fees generated by protocol-owned liquidity, some yield-generating activities, and services such as the incubator program, which offers support to early-stage DAOs in exchange for equity.',
    demandDrivers: [
      'Product deployment: Products and services that rely on OHM, such as liquidity pool pairings, generate demand for the token and increase liquidity.',
      "Financial services: Deployment of financial services that rely on OHM can generate demand and interest for the asset, reinforcing its overall value. However, due to the protocol's aim for stability, demand drivers relative to financial upside are limited.",
    ],
    tokenUtility:
      '$OHM and gOHM\nStaking: Users can stake OHM to receive gOHM, the governance token used to vote on proposals in the Olympus DAO.\nGovernance: gOHM enables token holders to vote on governance proposals and effectively steer the decisions taken by the Olympus DAO.\nYield-bearing: gOHM accrues more OHM over time, making it a lucrative investment.',
    coingeckoApiKey: 'olympus',
    price: 12.13,
    marketCap: 198610303,
    fdv: 325451723,
    volume: 131307,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Olympus%20Protocol%20Tokenomics.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1One4ULzvAufz9iwQRwo2Ne6l7ynGFZ2k%26export%3Ddownload',
    parent:
      'Olympus Protocol does not have predecessors from which it borrows ideas, but rather has been a predecessor for several projects that used the OHM concept. This happened in the winter of 2021 when several new protocols were created and labeled as OHM-forks, although they all failed to achieve their mission.',
    strongPoints:
      'The strength of the protocol relies on its protocol owned liquidity and large treasury (250M$+ in treasury assets) which allows to stabilize the price of the OHM token. The strength of the token relies on its achieved stability which makes it an appealing asset to be used in the DeFi ecosystem in lieu of dollar-pegged stablecoins.',
    weakPoints:
      'Because OHM is both the token and the product in Olympus, value capture is relatively difficult because it only reflects indirectly through increased backing. Furthermore, OHM is not yet backed by decentralized native crypto assets (e.g BTC or ETH), but rather by centralized stablecoins (e.g. DAI, USDC, FRAX). Finally, the majority of the assets in the treasury are deposited in other protocols, posing smart contract risk, albeit highly unlikely given the battle-tested nature of these protocols.',
    image: 'https://assets.coingecko.com/coins/images/14483/standard/token_OHM_%281%29.png?1696514169',
  },
  {
    title: 'Osmosis',
    ticker: 'OSMO',
    shortDescription:
      'Osmosis is an app-centric chain and DEX build in the Cosmos ecosystem. The protocol is a trading platform and automated market maker (AMM) protocol that allows developers to build customized AMMs with sovereign liquidity pools.',
    sector: 'DeFi',
    problem:
      'Liquidity issues and high fees often plague decentralized exchanges (DEXs), especially in executing cross-chain transactions.',
    solution:
      'Osmosis provides an app-centric chain and DEX powered by Cosmos and the IBC protocol, enabling seamless cross-chain transactions. Its customizable AMM supports developers in creating unique liquidity pools, ensuring ample liquidity for trading. With Osmosis, users can easily exchange tokens between blockchain networks with reduced fees and slippage.',
    mission:
      'Osmosis is an advanced Automated Market Maker (AMM) protocol developed using the Cosmos SDK. It allows developers to design, build, and deploy their customized AMMs. Emphasizing heterogeneity and sovereignty, core values of the Cosmos ecosystem, Osmosis integrates these principles into its protocol. It diverges from a uniform approach, offering a platform where the most efficient solutions can be realized through experimentation and the collective wisdom of its community. This is achieved by providing extensive customizability to AMM designers and a governance system for stakeholders to manage and direct their liquidity pools​​.\n',
    vision:
      'Osmosis envisions a long-term future where it serves as a central, cross-chain native platform within the blockchain ecosystem. From the outset, it is built with Inter-Blockchain Communication (IBC) to seamlessly connect with the vast network of Cosmos chains and their significant asset value. Osmosis plans to further integrate with non-IBC enabled chains, including Ethereum-based ERC20s and other blockchain platforms, using mechanisms like the Althea gravity bridge and custom pegs. This vision is centered on bridging various blockchain assets, facilitating a more interconnected and efficient DeFi environment​',
    valueCreation:
      'The value created by Osmosis is its decentralized exchange and automated market maker protocol, providing a flexible trading experience for specific projects with customized AMM pools and cross-chain asset trading for enhanced transparency and decentralization in DeFi.',
    revenueFrom: 'Transaction fees from the network, DEX trades, and liquidity provision/withdrawal actions.',
    revenueDenominatedIn: 'OSMO tokens.',
    revenueTo:
      'OSMO stakers who receive rewards from transaction fees collected by validators and airdrops from different Cosmos chains. Validators also receive a percentage of transaction fees.\nLiquidity providers',
    valueCaptureToken:
      "Osmosis protocol captures value through token holders staking and providing liquidity, earning yields in return. This incentivizes users to lock up tokens, reducing supply and increasing demand, which can potentially increase the token's value.",
    valueCaptureProtocol:
      'Osmosis also captures value through its protocol treasury, which receives a portion of transaction fees. The treasury can then use these funds for various purposes, such as funding development, marketing, or community initiatives. Additionally, stakers have control over the allocation of community pool funds, giving them a say in how the protocol treasury is used. Osmosis also offers stakers airdrops',
    demandDrivers: [
      'Governance:\nHolders of OSMO can participate in protocol governance decisions and have a say in the direction of the project.',
      'Short-term inflation rewards:\nUsers who provide liquidity to Osmosis pools can earn OSMO tokens as a reward, incentivizing them to stay engaged with the platform.',
      'Exposure to Cosmos ecosystem:\nHolding OSMO allows for potential airdrops and staking rewards from other chains in the Cosmos ecosystem, providing additional value to the token.',
    ],
    tokenUtility:
      "$OSMO\nGovernance: Osmosis token holders can vote on proposals that determine the direction of the network and protocol.\nTransaction Fees: Osmosis token is used to pay transaction fees on the Osmosis network.\nSecurity: Osmosis token holders can participate in staking and earn rewards by securing the network.\n\n$LP Tokens\nLiquidity Provision: Pool tokens represent liquidity providers' share in a liquidity pool, allowing them to earn trading fees and swap fees.\nYield Farming: Holders of pool tokens can earn additional rewards through yield farming incentives offered by the Osmosis protocol.",
    coingeckoApiKey: 'osmosis',
    price: 0.712059,
    marketCap: 449013993,
    fdv: 711974466,
    volume: 8200062,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Tokenomics%20101%20Osmosis%20DEX.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1oqgVdok1AXShgqz0CJSTyngD-l3wWdHf%26export%3Ddownload',
    parent:
      'Uniswap - A decentralized exchange (DEX) on the Ethereum blockchain that allows users to trade ERC20 tokens without intermediaries.\n\nPancakeSwap - A decentralized exchange (DEX) built on the Binance Smart Chain (BSC) that allows users to trade BEP20 tokens without intermediaries.\n\nSushiSwap - A decentralized exchange (DEX) on the Ethereum blockchain that offers yield farming and other liquidity incentives to users who provide liquidity to the platform.',
    strongPoints:
      '\nThe protocol is well-positioned with a competitive and innovative product and an excellent infrastructure to boost network effects.',
    weakPoints:
      '50% of tokens are still going to be issued and inflation might not be matched by the growth of the ecosystem.\nAlso, ‘mercenary’ money might leave when the incentives decay if there are no further developments.',
    image: 'https://assets.coingecko.com/coins/images/16724/standard/osmo.png?1696516298',
  },
  {
    title: 'Pendle Finance',
    ticker: 'PENDLE',
    shortDescription:
      'The Pendle protocol enables the permissionless tokenization and trading of yield. Pendle allows anyone to purchase assets at a discount, obtain fixed yield, or long DeFi yield.\nPENDLE is the platform token of Pendle, and is the cornerstone of value accrual mechanics and management of the protocol.',
    sector: 'DeFi',
    problem:
      'DeFi investors struggle to access discounted assets, fixed yield, or long DeFi yield due to the absence of permissionless yield markets.',
    solution:
      'Pendle Finance protocol enables easy access to yield markets through permissionless tokenization and trading of yield, including Uniswap pools. Pendle V2 upgrade and EIP5115: Standardized Yield (SY) simplify DeFi yield market growth, while vePENDLE utilities, such as channeling protocol incentives, add value for PENDLE token holders.',
    mission:
      "Pendle Finance's mission is to enhance yield management in decentralized finance (DeFi). It enables users to maximize returns from yield-bearing assets by breaking them into separate components. This process allows users to engage in more nuanced speculation on the changes in underlying yield, offering a sophisticated approach to asset management in the DeFi space",
    vision:
      "Pendle Finance's vision focuses on advancing and innovating yield management strategies in the DeFi ecosystem, aiming to provide users with flexible and powerful tools for optimizing their investment returns.\n\n\n\n\n\n",
    valueCreation:
      'Pendle enables users to split yield-bearing assets into their principal and yield components, principal token and yield token, allowing these to be traded separately.\nCreating a yield market in DeFi, Pendle unlocks the full potential of yield, enabling users to execute advanced yield strategies:\n- Buy assets at discount\n- Fixed yield for low-risk, stable growth\n- Gain long exposure to future yield',
    revenueFrom: '80% of swap fees, 3% of tokenized yield, and yields after maturity of YT/PT.',
    revenueDenominatedIn: 'ETH, WBTC, DAI, USDC, PENDLE emissions',
    revenueTo:
      'Liquidity providers receive 20% of swap fees. vePENDLE holders receive the remaining revenue, distributed pro rata based on vote weight and vePENDLE balance.',
    valueCaptureToken:
      'vePENDLE holders are entitled to a cut of protocol revenue collected, including a volume-weighted share of swap fees from voted pools (80%), 3% of YT yield fees, and all yields after maturity of YT/PT.',
    valueCaptureProtocol: 'None mentioned.',
    demandDrivers: [
      'vePENDLE:\nHolders of vePENDLE seek to boost pool rewards, receive protocol incentives, and share in the revenue of Pendle Finance as the demand for DeFi yield markets rise.',
    ],
    tokenUtility:
      "$PENDLE\nGovernance: staking and locking PENDLE into vePENDLE lets users participate in governance decisions.\nBoost LP rewards: Holding vePENDLE can increase liquidity provider rewards by up to 2x.\nRevenue share: vePENDLE holders receive a share of the protocol's revenue from options trading fees.\nVote for pools: vePENDLE holders can vote for pools to receive incentives and influence liquidity distribution.\nfee share: Users holding vePENDLE can receive a portion of swap fees generated by voted pools.",
    coingeckoApiKey: 'pendle',
    price: 1.11,
    marketCap: 107446048,
    fdv: 286423900,
    volume: 10509655,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1#G1PY5jewSHp1oNUl-VNuzjF3xHgAq-zBsi',
    parent:
      'Ribbon Finance - Ribbon Finance is a DeFi protocol that enables users to create and trade structured products such as yield curves and yield baskets.\nBarnBridge - BarnBridge is a DeFi protocol that enables users to hedge against yield sensitivity and manage risk through the creation and trading of structured tokens.\nOpyn - Opyn is a decentralized options protocol that allows users to buy and sell options on various DeFi assets, providing hedging and yield-enhancing opportunities.',
    strongPoints:
      'Investor tokens are fully unlocked and with the introduction of vePENDLE, market circulation will remain low.\nPendle is bringing the $6T fixed-income industry into DeFi - the largest financial segment in Tradfi that remains relatively untapped in DeFi\nvePENDLE accrues value directly from protocol incentives, which will help to align user + protocol incentives to foster long-term growth',
    weakPoints: '2% annual inflation',
    image: 'https://assets.coingecko.com/coins/images/15069/standard/Pendle_Logo_Normal-03.png?1696514728',
  },
  {
    title: 'Polkadot',
    ticker: 'DOT',
    shortDescription:
      "Polkadot is a sharded, multichain, heterogeneous layer 0 network made up of the base platform, known as the Relay Chain, and connected chains, known as parachains. The Relay Chain does not support application functionality; its purpose is to provide security to the network's application-specific chains i.e., parachains ",
    sector: 'Layer 1',
    problem:
      "Scalability issues and fragmentation of blockchains create inefficiencies in cross-chain transactions, limiting blockchain technology's potential.",
    solution:
      'Polkadot aims to tackle scalability challenges by enabling a shared security model for multiple blockchains. It also enables cross-chain transfers of assets and data using the XCM format, while the Substrate framework allows for easy creation of custom blockchains, expanding the Polkadot ecosystem.',
    mission:
      "Polkadot's mission is to enable a fully decentralized web, where users have complete control over their identity and data, free from central authority. It aims to connect diverse blockchain types — private, consortium, public, permissionless — and future technologies, using its relay chain for trustless information and transaction exchanges. The focus is on easing the creation and connection of decentralized applications, services, and institutions, empowering innovators to develop better solutions and freeing society from reliance on a flawed web dominated by large institutions",
    vision:
      "Polkadot's vision is for a fair, secure, and resilient web that aligns with human interests and wellbeing. It seeks to reclaim digital sovereignty from powerful third parties, laying the groundwork for new business models, services, organizations, and even entire societies. This next-generation blockchain protocol unites a network of specialized blockchains into a scalable ecosystem with secure cross-chain communication. The envisioned future includes protected digital infrastructure, accessible financial services, more representative and efficient trade networks, and a reliable infrastructure for collaboration, promoting decentralized, open-source, and peer-to-peer interactions",
    valueCreation:
      '-Polkadot is a payment network and foundation to run parachains which are sovereign blockchains.\n-The more users stake their $DOT, the more secure the network is.\n-Allows blockchains to connect in a permissionless and secure way, without bridging risk.\n-The ecosystem gains more value with each new parachain and user.\n',
    revenueFrom: 'Transaction fees paid by users of the network.',
    revenueDenominatedIn: 'DOT',
    revenueTo:
      '20% goes to validators, while 80% goes to the Polkadot treasury to fund future development, community projects, and grants.',
    valueCaptureToken:
      'As more people use Polkadot, demand for DOT increases, leading to potential price appreciation. Additionally, staking DOT is required for network participation, incentivizing users to hold and stake DOT, increasing scarcity and potentially driving up the price.',
    valueCaptureProtocol:
      'Polkadot captures value through transaction fees and locking up DOT in parachain auctions, increasing scarcity and potentially driving up the price. Additionally, the protocol treasury can use these funds for development and ecosystem growth.',
    demandDrivers: [
      'Staking yield:\nCrypto investors seeking passive income through staking DOT for network security.',
      'Parachain slot auctions:\nCrypto investors and projects looking to secure a parachain slot for interoperability and scalability.',
      'Transaction fees:\nUsers and businesses who want to use the Polkadot network for fast and cheap transactions.',
      "On-chain governance:\nDOT holders who want to participate in decision-making for the network's development and evolution.",
    ],
    tokenUtility:
      '$DOT\nPayment of transaction fees: DOT can be used to pay for transaction fees.\nParticipation in governance: DOT holders can vote on proposed upgrades.\nSecuring the network: DOT holders can stake their tokens to earn rewards and secure the network.\nParticipating in auctions: DOT holders can participate in parachain slot auctions used for decentralized app deployment.',
    coingeckoApiKey: 'polkadot',
    price: 5.6,
    marketCap: 7294017817,
    fdv: 7747117788,
    volume: 119453217,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Polkadot.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1S3e8c1_0PAXQbCSm9ivsKTSYO3GBn7YK%26export%3Ddownload',
    parent:
      'Cosmos Network - A decentralized network of interconnected independent blockchains, allowing them to communicate and transact with each other.\nAvalanche - A highly-scalable, open-source platform for launching decentralized applications and enterprise blockchain deployments.',
    strongPoints:
      '- Polkadot and DOT have a lot of use cases, and Polkadot is the second in terms of monthly developers after Ethereum. It uses parachains to handle scalability issues that have been an important problem for traditional blockchains.\n\n- The risk of bridging assets within the Polkadot ecosystem (between parachains) is much lower due to there being a common set of validators',
    weakPoints:
      "- DOT has an unlimited supply where yearly inflation changes between 7-10%.\n- The XCM format is still in its early days, where it isn't easy to maintain the connection between parachains.\n- Treasury inflow is denominated in DOT",
    image: 'https://assets.coingecko.com/coins/images/12171/standard/polkadot.png?1696512008',
  },
  {
    title: 'Qredo',
    ticker: 'QRDO',
    shortDescription:
      'Qredo offers decentralised custody to institutions. Multi party computation enables securing and trading of assets across chains. $QRDO is used to incentivise usage of the network, for staking and staking rewards.',
    sector: 'Layer 2',
    problem: 'Institutional investors face challenges in securely storing and trading assets across multiple chains.',
    solution:
      'Qredo provides decentralised custody to institutions, leveraging multi-party computation to secure and trade assets across chains. QRDO incentivises network usage through staking and rewards, with market makers required to stake and hold tokens for added security.',
    mission:
      "Qredo's mission is to transform the way institutions manage their cryptocurrency, offering a complex, multifaceted crypto ecosystem. The network is designed to enhance institutional investment in cryptocurrency, fostering a rich ecosystem with strategic investors and network partners. Qredo emphasizes solving inefficiencies in crypto management for institutions, focusing on secure, decentralized custody, greater control over crypto asset management, and robust governance and reporting features. This approach addresses the need for institutional-grade crypto solutions and seeks to empower institutions with greater flexibility and security in their crypto operations​",
    vision:
      "Qredo envisions becoming an independent decentralized entity, fully community-owned and operated. It aims to extend Satoshi Nakamoto's Bitcoin ethos to private key management, eliminating the need for trusted third parties and enhancing security. The vision includes a unique user-centric token model, promoting network adoption and utilization among diverse members. Qredo's path to decentralization reflects a commitment to fostering a secure, efficient, and user-empowering cryptocurrency ecosystem, aligning with the broader goals of blockchain technology and decentralized finance​",
    valueCreation:
      'Target institutions, it offers MPC custody of tokens secured by a PoS L2 blockchain and allows cross chain trading of synthetic assets with settlement on L1 when required. ',
    revenueFrom: 'Monthly fee from custody service users, and trade fee from traders.',
    revenueDenominatedIn: 'USD or Qredo token (QRDO).',
    revenueTo: 'Treasury or is redistributed to validators, with percentages not specified.',
    valueCaptureToken:
      '$QRDO is staked by market makers and validators, removing circulating supply and thus somewhat mapping the value created.',
    valueCaptureProtocol:
      'Qredo protocol captures value via a treasury that is funded by transaction fees and managed by a DAO, which allocates resources for ecosystem development and community initiatives.',
    demandDrivers: [
      'Market Maker Staking:\nMarket Makers must hold $QRDO to participate in the Qredo Network and provide liquidity, driving demand for the token.',
    ],
    tokenUtility:
      "$QRDO\nPoS Token: Qredo Network Protocol's $QRDO token is used as the proof-of-stake token for network security.\nMarket Maker Staking: $QRDO is required to be staked by market makers to participate in the Qredo market and earn trading fees.\nValidator Staking: Validators must stake $QRDO to participate in network consensus and receive rewards.\nNetwork Governance: $QRDO is used for governance decisions on the Qredo protocol.\nProtocol Fees: Transaction fees on the Qredo protocol are paid in $QRDO, creating demand for the token.",
    coingeckoApiKey: 'qredo',
    price: 0.060164,
    marketCap: 38084508,
    fdv: 97722550,
    volume: 2624030,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=tokenomics_QREDO.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1V08nG9EhpkXJ618XVQhqVs7cwlK_-nEx%26export%3Ddownload',
    parent:
      'Oasis Network: A privacy-focused blockchain platform that uses secure multi-party computation (MPC) to enable secure and scalable data analysis and processing.\n\nEnigma: A privacy-focused blockchain protocol that enables secure and decentralized computation of sensitive data through the use of MPC and secure enclaves.\n\nNuCypher: A privacy-focused decentralized key management and encryption network that uses MPC to provide secure access controls for data sharing and storage across multiple nodes.',
    strongPoints: 'Qredo has got some good partnerships including MetaMask',
    weakPoints:
      'The token is not really embedded into the application hand lacks real use or connection to the business model.\nMost token allocations are vesting over the coming year.',
    image: 'https://assets.coingecko.com/coins/images/17541/standard/qrdo.png?1696517078',
  },
  {
    title: 'Radix',
    ticker: 'XRD',
    shortDescription:
      'Radix is a DeFi-oriented, delegated proof of stake Layer-1 that offers a platform for developers to build safe, scalable, and intuitive dApps.',
    sector: 'DeFi / Layer 1',
    problem:
      'DeFi developers spend only 10% of their time building the core business logic for their dApps. The other 90% is spent ensuring it is safe for public use.',
    solution:
      'Radix has developed a programmable DeFi engine that will speed up development akin to what the video gaming engine did for video game development.',
    mission:
      "Radix's mission is to provide global, friction-free access to the digital economy. It aims to democratize the digital revolution that has been largely confined to wealthier regions and individuals. By lowering technical and economic barriers, Radix seeks to empower individuals, enabling anyone, regardless of knowledge or expertise, to participate in the economy, create global products and services, and access financial opportunities. The focus is on leveraging Distributed Ledger Technology (DLT) to broaden opportunities beyond privileged circles, facilitating capital raising and payments without traditional banking dependencies",
    vision:
      "Radix envisions itself as a platform for building world-changing applications, touching every industry and individual globally. It sees its role as providing the tools and mechanisms necessary for the success of others, emphasizing the importance of enabling others rather than direct financial gain. Radix's commitment to long-term development and impact reflects a dedication to creating a platform that progressively evolves to meet the needs of a diverse range of users and innovators. The vision is centered on fostering a transformative movement in the digital and financial landscape, prioritizing functionality, user empowerment, and global impact over immediate profit​",
    valueCreation:
      "Lower Development Times: \nRadix's DeFi engine allows developers to spend more time building out the business logic of their dApps, spending less time on boilerplate code, testing, and edge cases. \n\nSecurity:  \nBuilt on Rust, Radix's smart-contract programming language, \"Scrypto,\" takes an asset-oriented approach. Assets will be recognized as first-class citizens of the ledger. Coupled with a Finite State Machine, the probability of bugs, errors, and exploits is dramatically reduced. \n\nScalability: \nRadix's Cerberus consensus is a unique approach to achieving near-unlimited scalability for DLT networks. Unlike traditional consensus algorithms that require all nodes to validate every transaction, Cerberus divides the network into smaller, more manageable groups called shards. Each shard validates a subset of transactions, allowing for parallel processing and faster transaction times. ",
    revenueFrom:
      '- Protocol emissions are solely sourced through staking.\n- Amount to an estimated 300M units per year.\n- Due to be released every year for the next 40 years.\n-Update: Since the Babylon release, 50% of transaction fees are burnt, while 50% are allocated to validators.',
    revenueDenominatedIn: '$XRD',
    revenueTo:
      '- Ecosystem participants that help perform consensus and ensure network security.\n- The two main participants that perform these functions are Validator Nodes and Delegators.\n- Radix Foundation and its subsidiaries that operate 4 out of the 100 active validator nodes.',
    valueCaptureToken:
      'Locking tokens: \nThe circulating supply is reduced as users stake their tokens to participate in consensus and earn yield.\n\nBurning fees: \nThe more transactions on the ledger, the more fees denominated in $XRD are burnt. ',
    valueCaptureProtocol:
      'Half of the transaction fees are burnt, while the remaining are allocated to validators. Therefore, other than token allocation, no value or revenue of this kind is accrued to the treasury of the Radix Foundation, RDX Works Ltd., or any of their subsidiaries.',
    demandDrivers: [
      "Gas Fees - (Utility)\nIndividuals that are utilizing the ecosystem's dApps will need to purchase and hold $XRD for paying gas fees.",
      'Node Requirement - (Utility)\nNodes require a certain amount of $XRD as collateral in order to participate in network and security.',
      'Staking Rewards - (Mechanism)\nUsers looking to gain a yield will purchase and hold $XRD.',
      "Protocol Success - (Speculation)\nInvestors and traders are confident in the team's abilities at RDX Works, and Radix's DeFi dApp ecosystem will purchase and hold $XRD. ",
    ],
    tokenUtility:
      "The $XRD token provides utility in the following ways:\nGas token: \nRequired to pay gas fees.\n\nNetwork Securing:\nNodes are required to lock $XRD and users are able to delegate their stake to validators in order to secure the network.\n\nMedium of exchange\n$XRD is the most prominent coin used for purchasing goods or services on the network's dApps.",
    coingeckoApiKey: 'radix',
    price: 0.057766,
    marketCap: 595792185,
    fdv: 734430522,
    volume: 3295373,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Radix_ValueFlowDiagram_BabylonUpdate.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1Nivaht4wzkbKj9-iB42BQa0KEFrffLOY%26export%3Ddownload',
    parent:
      "Solana: Solana is a high-performance blockchain platform designed for decentralized app development, offering a scalable and secure ecosystem with fast transaction speeds.\n\nKey Facts:\n- Solana uses a hybrid consensus model of a novel proof-of-history (PoH) algorithm combined with a proof-of-stake (PoS) version. \n- It can reach over 50,000 transactions per second (TPS). \n- Smart contracts are written in the Rust programming language.\n\nAptos: Aptos is a Layer 1 blockchain that prioritizes safe development and user experience.\n\nKey Facts:\n- Aptos uses Proof-of-Stake (PoS) as its consensus algorithm. \n- Aptos Labs, the organization that developed the Aptos blockchain, is led by Mo Shaikh and Avery Ching, who previously worked on Meta's blockchain project Libra. \n- The Move language, the Aptos data model, and the Move module are key components of the Aptos Blockchain.\n\nSui: Sui is a revolutionary decentralized Layer 1 blockchain that redefines the concept of asset ownership.\n\nKey Facts:\n- Sui is a Delegated Proof of Stake (PoS) blockchain.\n- Validators can scale horizontally by adding more computing power to increase transaction throughput.\n- Sui's smart contracts are written in Move, a Rust-based programming language that prioritizes fast and secure transaction executions.\n",
    strongPoints: null,
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/4374/standard/Radix.png?1696504973',
  },
  {
    title: 'Thorchain',
    ticker: 'RUNE',
    shortDescription:
      'Swap native ETH for BTC without intermediary. The RUNE token ties together a blockchain, liquidity providers and node operators to enable a decentralized Cross-Chain Liquidity Network.',
    sector: 'Layer 1',
    problem:
      'Crypto traders face increased costs and security risks when relying on intermediaries to swap their native ETH for BTC.',
    solution:
      "Thorchain's Cross-Chain Liquidity Network eliminates intermediaries and enables secure and direct swaps between native ETH and BTC. The RUNE token unites liquidity providers and node operators to ensure reliability and cost-effectiveness for crypto traders.",
    mission:
      "Thorchain's mission is to facilitate seamless cryptocurrency swaps between blockchains without the need for wrapping tokens. As a decentralized exchange (DEX) with an AMM-based protocol, it operates independently, using the Tendermint consensus engine, Cosmos-SDK state machine, and GG20 Threshold Signature Scheme (TSS). Thorchain emphasizes enabling users to swap native assets across multiple chains transparently and fairly, without relying on centralized third parties, and manages funds directly in on-chain vaults for economic security​",
    vision:
      "Thorchain's vision revolves around three key pillars: Security, Liquidity, and Volume. It aims to improve network security through functional, procedural, and economic means, enhance liquidity with better UX and Total Value Locked (TVL), and increase network volume through user-friendly swap interfaces and wallet integrations. The platform is designed for inclusivity and security, allowing anyone to join the network permissionlessly while maintaining anonymity. Thorchain's high churn schedule for nodes ensures the network is censorship-resistant, avoids capture, and resists centralization, embodying the core principles of decentralization and scalability in blockchain technology",
    valueCreation:
      'Provide the infrastructure for completely decentralised cross chain swaps of crypto assets. Wallet to wallet without leaving your custody.',
    revenueFrom:
      'Transaction fees paid by users for swapping cryptocurrencies, a portion of which is distributed to LP and Node operators as rewards.',
    revenueDenominatedIn: 'RUNE token.',
    revenueTo:
      'LP and Node operators as rewards for providing liquidity and securing the network, with a percentage allocated to the Community Pool for network upgrades and development.',
    valueCaptureToken:
      'RUNE token accrues value through its use as a native asset for cross-chain swaps, providing liquidity to other networks, and as a settlement currency.',
    valueCaptureProtocol:
      "Thorchain protocol captures value through a treasury that is funded by a portion of the swap fees. This treasury is used to fund development, incentivize liquidity providers and node operators, and buy back RUNE tokens. The more swaps and liquidity provision, the more value is captured for the protocol's treasury.",
    demandDrivers: [
      'Swap Fees: \nTraders will need to buy and hold RUNE to pay for swap fees on the Thorchain protocol.',
      'Liquidity Providers: LPs need to pair their token liquidity with RUNE at a 1:1 ratio, creating demand for RUNE.',
      'Node Operators: Node operators must bond RUNE based on 2x the total LP pool amount, leading to increased demand for RUNE.',
      'Total Value Locked (TVL): The above factors result in a 3x RUNE demand per $TVL on the Thorchain protocol, making it attractive for investors to hold RUNE.',
    ],
    tokenUtility:
      "$RUNE\nFee mechanisms for swaps: RUNE is used to pay for fees associated with swaps on Thorchain, incentivizing liquidity providers and validators.\nPoS-Token: As a PoS token, RUNE holders can participate in network governance and earn rewards for staking their tokens.\nDeposit and bonding token: RUNE is used as a deposit and bonding token for liquidity providers who earn rewards for providing liquidity to Thorchain's AMM pools. LPs can also participate in governance by bonding their RUNE.",
    coingeckoApiKey: 'thorchain',
    price: 7.14,
    marketCap: 2147750794,
    fdv: 3571245074,
    volume: 680962603,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=tokenomics_thorchain#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1DRTHbOP9r22VcmFJJ7K-eFxvLh0hYWgi%26export%3Ddownload',
    parent:
      'Ren Protocol: Enables decentralized cross-chain liquidity for various assets, including Bitcoin, Ethereum, and Zcash, using RenVM.\n\nKyber Network: A decentralized exchange protocol that facilitates the swapping of various cryptocurrencies without the need for intermediaries.',
    strongPoints:
      'decentralised exchanges play an important role. Even more important if assets can be swapped cross-chain\nThe team has learned from the early hacks and exploits',
    weakPoints:
      'RUNE token is directly tied to TVL is an interesting concept. When TVL rises, the circulating supply of RUNE goes down. Equally, when TVL sinks, the circulating supply of RUNE increases.',
    image: 'https://assets.coingecko.com/coins/images/6595/standard/Rune200x200.png?1696506946',
  },
  {
    title: 'The Graph',
    ticker: 'GRT',
    shortDescription:
      'The Graph is a decentralised protocol that efficiently indexes and queries blockchain data. Developers can organise dApp data without relying on centralised data services.',
    sector: 'Aggregator',
    problem:
      'dApps need fast and reliable indexing and querying of blockchain data. But, centralised data services bring trust and censorship issues.',
    solution:
      'The Graph protocol provides decentralised indexing and querying of blockchain data, helping developers build robust dApps. Open APIs incentivize an indexer community to offer fast, reliable data services. This eliminates centralised data services, ensuring trust and transparency.',
    mission:
      "The Graph's mission is to enable full-stack decentralized applications through an indexing protocol for querying blockchains. It aims to make decentralization a widespread reality, building on the foundations laid by Bitcoin and Ethereum. The Graph introduces a global data layer over blockchains and storage networks, organizing and unifying the decentralization movement. It focuses on solving the 'last mile' problem of providing clients with efficiently served, verifiable data, enabling competitive product experiences against traditional web applications. The Graph is dedicated to advancing open data built on Web3 and supporting network participants and developers as the network grows and integrates multi-blockchain functionality",
    vision:
      "The Graph's vision is centered on promoting and facilitating decentralized data access and application development. The Graph's commitment to decentralization, efficient data querying, and support for a multi-blockchain environment envisions a more interconnected, user-empowered, and transparent digital ecosystem.",
    valueCreation:
      'The Graph creates value by allowing dapps to search and access the blockchain without proprietary indexing services. It powers many popular dapps and DeFi protocols by providing a decentralized protocol for querying data on blockchains and storage networks.',
    revenueFrom: 'Query fees paid by data consumers to Indexers for data query services on the blockchain',
    revenueDenominatedIn: "GRT (The Graph's native token)",
    revenueTo:
      'Indexers who provide data query services, with a 90% share of query fees, and The Graph Foundation, which takes a 10% cut.',
    valueCaptureToken:
      'GRT is staked by Indexers, Curators, and Delegators to align incentives. Data consumers pay query fees to access information, allowing GRT to capture value based on supply and demand.',
    valueCaptureProtocol:
      "The Graph protocol facilitates decentralized data indexing and querying. The protocol's value is captured through the successful operation of its network, enabling data consumers to access trustworthy information from blockchain networks.",
    demandDrivers: [
      'Delegators: \nInvestors who stake tokens to earn returns.',
      'Indexers: \nParticipants who earn rewards by indexing and querying data.',
      'Curators: \nSignal subgraphs for rewards.',
      'Data Consumers: \nNeed GRT to pay for query services.',
    ],
    tokenUtility:
      '$GRT\nStaking: Participants can use GRT to stake and become Indexers or Curators, earning rewards for their contributions to the network.\nQuery fees: GRT is used to pay for query fees by Data Consumers who need access to subgraphs on The Graph network.\nGovernance: GRT holders can participate in governance proposals to influence the direction of the network.\nIncentivization: GRT is used to incentivize behavior that benefits the network, such as data curation and efficient resource allocation.\nLiquidity: GRT can be traded on various cryptocurrency exchanges, providing liquidity for holders and facilitating access to the token for new participants.',
    coingeckoApiKey: 'the-graph',
    price: 0.15291,
    marketCap: 1425205651,
    fdv: 1529027836,
    volume: 41323431,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=GRT%20tokenomics%20draft6.png#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1SiWnUzmLJOP-HrMUBme_DjpZ9bVKVd03%26export%3Ddownload',
    parent:
      'Chainlink - A decentralised oracle network that securely connects smart contracts to external data sources, enabling the creation of complex dApps.\nOcean Protocol - A blockchain-powered data exchange protocol that allows developers to share, access, and monetize data while maintaining privacy and control.\nArweave - A decentralised, permanent data storage network that allows developers to store and retrieve data without relying on centralised cloud services.',
    strongPoints:
      'The Graph is a key protocol for the Web3 infrastructure industry.\nThe Graph succeeded at value creation, and the ecosystem is maturing as the network usage increases.',
    weakPoints:
      'Inflation may occur via annual issuance of new tokens which is set at around 3%. Token burning is less than 1%.\nOver 70% of GRT supply is in circulation (7,400,273,157 GRT). One of the biggest challenges going forward is finding demand drivers for the rest of the tokens.',
    image: 'https://assets.coingecko.com/coins/images/13397/standard/Graph_Token.png?1696513159',
  },
  {
    title: 'The Sandbox',
    ticker: 'SAND',
    shortDescription:
      'The Sandbox Protocol is a blockchain-based gaming platform that allows players to create and own virtual assets in a Minecraft-style world. It uses non-fungible tokens (NFTs) to represent these assets, giving players true ownership and the ability to trade them in a decentralized marketplace. The platform also includes a game creation tool that lets players design and share their own games within the Sandbox world.',
    sector: 'Metaverse',
    problem:
      'Players cannot often+ truly own and monetize their in-game assets on traditional game platforms, leading to less investment of time and money.',
    solution:
      'The Sandbox Protocol combines Minecraft-like game style with NFT-based asset ownership to enable players to truly own and trade their in-game assets. Players can earn rewards through staking and governance participation, incentivizing them to invest more and leading to a more engaged player base.',
    mission:
      'The Sandbox is committed to successfully integrating blockchain technology into mainstream gaming. It offers a unique "play-to-earn" model, enabling users to be creators and gamers at the same time. By leveraging blockchain technology, it uses the SAND token to facilitate platform transactions, enhancing user engagement and creativity​',
    vision:
      'The Sandbox envisions being the "Manhattan of the metaverse", bringing together a diverse mix of exciting brands and artists. Their goal is to create an immersive, collaborative space without intending to replace reality. This vision promotes a balance between digital interaction and the real world, fostering a creative and engaging community​',
    valueCreation: 'Providing a platform to build games, assets and experiences and allowing players to play.',
    revenueFrom: 'Transaction fee on all $SAND transactions.',
    revenueDenominatedIn: '$SAND tokens.',
    revenueTo:
      "Protocol's treasury and validators who secure the network. Treasury funds protocol development, marketing, partnerships. Validators receive a portion of transaction fees as staking rewards (currently 16%).",
    valueCaptureToken:
      '$SAND is the primary payment method for goods and services within The Sandbox protocol. As demand for these goods and services increases, the value of $SAND may also increase due to supply and demand dynamics.',
    valueCaptureProtocol:
      'The Sandbox protocol captures value through a 5% transaction fee, with 50% of that fee being paid to $SAND stakers as a reward. The other 50% is used to fund grants that aim to improve and enhance the protocol, leading to further value creation.',
    demandDrivers: [
      'Medium of Exchange: \n$SAND is required to transact',
      'Staking Rewards:\nCrypto investors seeking yield on their $SAND holdings. Staking creates scarcity of Gems and Catalysts, driving demand for the protocol, and rewards stakers with transaction fees.',
    ],
    tokenUtility:
      '$SAND\nPayment for goods and services: The Sandbox protocol uses SAND as its primary utility token, allowing users to buy and sell assets within the platform.\nStaking: SAND holders can earn staking rewards by participating in governance',
    coingeckoApiKey: 'the-sandbox',
    price: 0.43069,
    marketCap: 905205875,
    fdv: 1292085633,
    volume: 64376461,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Tokenomics%20101%20sandbox.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1dki3D0Cw_gxo7Uo-5-B4HkaFr-CgGeXo%26export%3Ddownload',
    parent:
      'Decentraland - A virtual world where users can create, experience, and monetize content and applications.\n\nSomnium Space - A social VR platform where users can create, explore, and monetize their virtual experiences and assets.\n\nCryptoVoxels - A blockchain-based virtual world where users can buy, build, and monetize their digital land and creations.',
    strongPoints:
      'A proven game approach (see Roblox / Minecraft) with ownership has potential if the Sandbox is able to compete\ndemand comes from the ability to farm scarcity for assets (gems and catalysts) via staking.\nThe ecosystem has many big names  on board',
    weakPoints:
      'Medium of exchange token has low hold-ability which implies different pricing dynamics. Higher transaction volume means more revenue from transaction fees which benefits stakers and the foundation',
    image: 'https://assets.coingecko.com/coins/images/12129/standard/sandbox_logo.jpg?1696511971',
  },
  {
    title: 'Uniswap',
    ticker: 'UNI',
    shortDescription:
      'Uniswap is a decentralized exchange (DEX) on the Ethereum blockchain, allowing users to swap and provide liquidity without permission. It operates on five chains, including L2s, and is among the first DEXs, enabling seamless token trading and liquidity provision without intermediaries.',
    sector: 'DeFi',
    problem:
      'Centralized exchanges have slow transactions, high costs, limited trading pairs, and lack transparency, which decreases liquidity and increases risks for traders.',
    solution:
      "Uniswap is a decentralized exchange on Ethereum that solves these issues. Its automated market maker algorithm allows for fast trades and high liquidity for any ERC-20 token. Additionally, Uniswap's open-source protocol provides transparency and security, mitigating trading risks for users.",
    mission:
      "Uniswap's mission is to revolutionize crypto trading by offering a decentralized protocol for swapping, earning, and building. It nurtures a growing network of DeFi apps, encouraging participation from developers, traders, and liquidity providers. The platform aims to create an open financial marketplace accessible to everyone, promoting inclusivity and innovation",
    vision:
      "Uniswap envisions a future where financial infrastructure is open, transparent, and community-governed. Through Uniswap v4, they aim to enable custom liquidity and trading solutions, fostering an ecosystem of fast, safe, and efficient decentralized exchanges. Their commitment lies in empowering users to influence the protocol's direction, maintaining a dynamic and user-centric ecosystem",
    valueCreation:
      'The value created by Uniswap protocol enables decentralized and permissionless trading of cryptocurrencies without intermediaries. This offers greater asset control, and enhanced privacy/security. The AMM algorithm ensures fair pricing, while liquidity providers earn fees. Uniswap supports multiple blockchains, promoting accessibility and interoperability. It has democratized financial services and propelled DeFi growth.',
    revenueFrom: '0.3% fee on trades in liquidity pools.',
    revenueDenominatedIn: 'whatever assets are being traded.',
    revenueTo:
      '100% of fees are directed to Liquidity Providers (LPs). Uniswap treasury does not generate income, and only holds $UNI tokens.',
    valueCaptureToken:
      "$UNI token holders do not receive any fees generated from Uniswap pools, but they do have governance rights that allow them to vote on proposals that impact the protocol's future. As the platform grows and becomes more valuable, demand for $UNI tokens may increase, causing their price to rise.",
    valueCaptureProtocol:
      'Uniswap does not capture any additional value directed to its treasury since all fees go to liquidity providers. However, due to $UNI speculation',
    demandDrivers: [
      'Governance rights\nAs the sole utility of the $UNI token, users holding the token can exercise voting power in the Uniswap governance system. This attracts individuals who are invested in the long-term success of the protocol and want a say in its future direction.',
      'Potential fee-sharing\nDiscussions around fee-sharing with $UNI token holders hav generated interest among traders and investors seeking additional financial incentives. This could further drive demand for the token as it potentially o',
    ],
    tokenUtility:
      '$UNI\nGovernance: UNI holders can vote on proposals to update the protocol, including fee changes and new token listings.\nLiquidity provision: Users can provide liquidity to earn fees. There was also a liquidity mining phase where liquidity providers (LPs) received UNI rewards.',
    coingeckoApiKey: 'uniswap',
    price: 6.18,
    marketCap: 4659824305,
    fdv: 6182051434,
    volume: 223945070,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Uniswap%20Tokenomics#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D19yleLuYpB-Fc8W2UUIceFOFj3A9dTssB%26export%3Ddownload',
    parent:
      'SushiSwap - A decentralized exchange platform that uses an automated market maker (AMM) model similar to Uniswap.\nBalancer - A decentralized exchange platform that allows for the creation of customized pools with varying token weights.\nCurve - A decentralized exchange platform that focuses on stablecoins and provides low-slippage trades through a specialized AMM algorithm.',
    strongPoints:
      'Largest DEX by volume.\n76% of the tokens are already in circulation, remaining 24% will be unlocked in 2 years.',
    weakPoints: 'UNI token does not have any utilities other than governance rights.',
    image: 'https://assets.coingecko.com/coins/images/12504/standard/uni.jpg?1696512319',
  },
  {
    title: 'Yearn Finance',
    ticker: 'YFI',
    shortDescription:
      'Yearn Finance is a yield aggregator that allows users to deposit their assets into Vaults, which generate profit by being deployed across several protocols in the DeFi ecosystem',
    sector: 'DeFi',
    problem: 'Yearn Finance aims to solve the problem of the lack of optimal yield aggregation across DeFi',
    solution: 'It does this by providing automated yield farming strategies through its yVaults.',
    mission:
      'Yearn.Finance, a trailblazer in decentralized finance, aspires to be the "Amazon of DeFi" by offering automated investment opportunities with substantial yields. Their platform is designed to revolutionize DeFi investments, providing users with efficient, high-yield options in a decentralized environment​',
    vision:
      "Yearn Finance's vision is to uphold a leaderless, decentralized financial system. They emphasize having no official voice, no central authority, and no traditional investment structure, focusing instead on community-driven operations. This approach ensures a democratic, participant-centric ecosystem where decisions and contributions are decentralized and collaborative",
    valueCreation:
      'The yVaults are effectively Yearn’s primary value creation product, allowing users to earn optimized yield simply by depositing their assets in the protocol.\n\nValue is captured by the protocol via the collection of fees, which are Yearn’s primary revenue source. These fees contribute to Yearn’s treasury growth and are reinvested in work being done by core contributors to improve the protocol.',
    revenueFrom:
      '- Management fee, which is paid for utilizing the services offered by Yearn finance, which are maintained by the protocol and its community\n- Performance fees, which is a fee that gets dedicated from the profit obtained via yield strategies that the users invest in',
    revenueDenominatedIn:
      'The revenue accrued by the protocol as fees from yVaults is denominated in the underlying asset being deposited in the vault, such as ETH, USDC, DAI etc.',
    revenueTo:
      'The fees being collected are then primarily directed to Yearn’s treasury, which employs it for various uses. The most important of these uses, after the passing of YPI-56, is to employ a portion of these fees to buy back YFI tokens through the BABY program. The remaining treasury funds are used for development costs, operating expenses and for retaining a liquidity buffer that is required to navigate periods of market uncertainty.',
    valueCaptureToken:
      'Value is also accrued to the token, in terms of price increase, via the BABY program, which effectively buys $YFI on open market. These buys create positive buy pressure from the protocol itself, sustaining $YFI price.',
    valueCaptureProtocol:
      'Value is captured by the protocol via the collection of fees, which are Yearn’s primary revenue source. These fees contribute to Yearn’s treasury growth and are reinvested in work being done by core contributors to improve the protocol.',
    demandDrivers: [
      'Governance\n- YFI token holders: general users want to hold YFI due to the governance rights that are associated with the token, through its locking mechanism into veYFI. As longer and bigger veYFI locks give more governance power, demand for YFI is expected to increase. Since proposals can change financial parameters such as reward structures and fees distribution, users would ultimately want to acquire and hold the token to participate in governance and capture value from protocol earnings.',
      'Yield Maximization:\n- users that have deposited in yVaults want to maximize their yields on deposits by acquiring more YFI to lock in veYFI for boosted gauge rewards.',
    ],
    tokenUtility:
      "YFI's main utility is that of a governance token, giving voting rights to YFI holders over governance proposals. A further breakdown of the governance rights is the following:\nSubmission and voting on Yearn Improvement Proposals (YIPs), which govern the protocol and define changes to its tokenomics\nVoting on how to allocate fees that are collected by Yearn Finance on yVaults through different veYFI gauge weights. The gauge weights essentially determine yield distributions\nVoting to authorize treasury spending and YFI buybacks via the BABY program\nSetting of yVaults parameters, such as minimum deposit thresholds\n\nIn addition to governance, YFI is also used to earn boosted rewards via its locking in veYFI. It is important to specify that both governance power and yield rewards are proportional to veYFI lock size and duration.\n",
    coingeckoApiKey: 'yearn-finance',
    price: 8703.97,
    marketCap: 289207367,
    fdv: 319126626,
    volume: 110269524,
    diagramUrl:
      'https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1#G189_A09i-S6no_TiGFj5Y_sPUk2i-yCCh',
    parent: 'The main competitor of Yearn Finance is Beefy Finance and Convex Finance',
    strongPoints: null,
    weakPoints: null,
    image: 'https://assets.coingecko.com/coins/images/11849/standard/yearn.jpg?1696511720',
  },
] as const
